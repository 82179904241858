import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import scrollToTop from './scrollToTop'
import {
  faChevronRight,
  faClock,
  faMicrophoneAlt,
} from '@fortawesome/free-solid-svg-icons'
import { useStateContext } from '../context/StateContext'
import { replaceSpaces } from '../utility'

const WebinarCard = ({
  image,
  title,
  description,
  speaker,
  date,
  time,
  id,
}) => {
  const plan = localStorage.getItem('plan')
  const { token } = useStateContext()
  const navigate = useNavigate()

  const formatText = description.split('<br>').map((item, index) => (
    <React.Fragment key={index}>
      {item}
      <br />
      <br />
    </React.Fragment>
  ))

  return (
    <div className='grid grid-cols-1 p-2 my-6 mx-8 border-2  bg-white shadow-lg border-gray-200 w-[100%] rounded-xl md:grid-cols-2 mx-20'>
      <div className='flex items-center justify-center '>
        <img
          src={image}
          alt=''
          className=' p-2 flex items-start justify-start'
          loading='lazy'
        />
      </div>
      <div className='flex flex-col m-2 items-center justify-center '>
        <h2 className='text-3xl text-[--main-color] text-center font-bold m-2'>
          {title}
        </h2>
        <h3 className='text-black text-xl font-[500] my-2 text-justify'>
          {formatText}
        </h3>
        <h5 className='flex mt-2 mb-2 text-base md:md:text-xl font-semibold'>
          <FontAwesomeIcon icon={faMicrophoneAlt} className='mt-1' />
          &nbsp; Speaker: &nbsp; <span>{speaker} </span>{' '}
        </h5>
        <h3 className='flex mb-8 text-base md:text-xl font-semibold'>
          <FontAwesomeIcon icon={faClock} className='mt-1' />
          &nbsp; Time: &nbsp; <span>{date}</span>&nbsp; at {time} hrs
        </h3>
        <div className='flex flex-wrap items-center justify-center mt-auto gap-4'>
          <button
            onClick={() => {
              scrollToTop()
              !token
                ? navigate('/subscription')
                : navigate(`/webinar/${replaceSpaces(title)}+${id}`, {
                  state: { id: id },
                })
            }}
            className='bg-[--main-color] text-white font-bold text-xl h-12 w-44'>
            Play Now &nbsp;
            <FontAwesomeIcon icon={faChevronRight} className='mt-1' />
          </button>
          <button
            className={`bg-[--main-color] ${plan === 'bronze' ? 'hidden' : 'visible'
              } text-white font-bold text-xl h-12 w-44`}>
            Subscribe Now &nbsp;
            <FontAwesomeIcon icon={faChevronRight} className='mt-1' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default WebinarCard
