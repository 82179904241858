import { createContext, useReducer } from 'react'

export const CartContext = createContext({
  cart: [],
  taxes: 0,
  grandTotal: 0,
  discountAmount: 0,
  plan: '',
  planPrice: 0,
  addToCart: () => { },
  removeFromCart: () => { },
  restoreCart: () => { },
  reduceDiscount: () => { },
  removeDiscount: () => { },
  resetCart: () => { },
  addPlan: (plan) => { },
  changePlanPrice: (price) => { },
})

const initState = {
  cart: [],
  taxes: 0,
  grandTotal: 0,
  discountAmount: 0,
  plan: '',
  planPrice: 0,
}

/**
 *
 * cart[] = {
id: string
image: string
name: string
price: number
qty: number
discountAmount: 
plan: '' | 'super' | 'bronze' | 'gold' | 'silver'
planOffer: {
  planName: string
  description: string
  magnitude: number
  unit: string
  _id: string
}
discount: {
  description: string
  magnitude: number
  quantity: number 
  unit: string
}[]
}
 */

const reducer = (state, action) => {
  const { type, payload } = action

  if (type === 'ADD') {
    const existingCart = [...state.cart]

    const index = existingCart.findIndex((el) => el.id === payload.id)

    // if item exits
    if (index >= 0) {
      const cart = { ...existingCart[index] }

      const qty = cart.qty + 1

      cart.qty = qty

      // modifying discount amount
      let discountAmount = 0

      cart.discount.forEach((el) => {
        if (qty >= el.quantity) {
          discountAmount = el.magnitude
        }
      })
      cart.totalPrice = qty * cart.price
      cart.discountAmount = discountAmount

      existingCart.splice(index, 1, cart)
    }
    // if no item exists
    else {
      existingCart.push({
        ...payload,
        qty: 1,
        totalPrice: payload.price,
        discountAmount: 0,
      })
    }

    localStorage.removeItem('cart')

    const totalPrice = existingCart.reduce(
      (prev, cur) => cur.totalPrice + prev,
      0
    )

    const QTY = existingCart.reduce((prev, cur) => cur.qty + prev, 0)

    localStorage.setItem(
      'cart',
      JSON.stringify({
        ...state,
        cart: existingCart,
        grandTotal: totalPrice + state.taxes,
        discountAmount: 0,
        plan: existingCart.length > 0 || QTY > 0 ? 'super' : '',
        planPrice: existingCart.length > 1 || QTY > 1 ? 0 : state.planPrice,
      })
    )

    return {
      ...state,
      cart: existingCart,
      grandTotal: totalPrice + state.taxes,
      plan: existingCart.length > 0 || QTY > 0 ? 'super' : '',
      planPrice: existingCart.length > 1 || QTY > 1 ? 0 : state.planPrice,
    }
  }

  if (type === 'REMOVE') {
    const existingCart = [...state.cart]

    const index = existingCart.findIndex((el) => el.id === payload)

    if (index >= 0) {
      const cart = { ...existingCart[index] }

      if (cart.qty > 1) {
        cart.qty -= 1

        cart.totalPrice = cart.qty * cart.price

        // modifying discount amount
        let discountAmount = 0

        cart.discount.forEach((el) => {
          if (cart.qty >= el.quantity) {
            discountAmount = el.magnitude
          }
        })

        cart.discountAmount = discountAmount

        existingCart.splice(index, 1, cart)
      } else {
        existingCart.splice(index, 1)
      }
    }

    const totalPrice = existingCart.reduce(
      (prev, cur) => cur.totalPrice + prev,
      0
    )

    const QTY = existingCart.reduce((prev, cur) => cur.qty + prev, 0)

    localStorage.removeItem('cart')
    localStorage.setItem(
      'cart',
      JSON.stringify({
        ...state,
        cart: existingCart,
        grandTotal: totalPrice + state.taxes,
        discountAmount: 0,
        plan: existingCart.length > 0 || QTY > 0 ? 'super' : '',
        planPrice: existingCart.length > 1 || QTY > 1 ? 0 : state.planPrice,
      })
    )

    return {
      ...state,
      cart: existingCart,
      grandTotal: totalPrice + state.taxes,
      plan: existingCart.length > 0 || QTY > 0 ? 'super' : '',
      planPrice: existingCart.length > 1 || QTY > 1 ? 0 : state.planPrice,
    }
  }

  if (type === 'DISCOUNT') {
    const existingCart = [...state.cart]

    const cart = { ...existingCart[payload.index] }

    cart.totalPrice = cart.totalPrice - payload.amount

    existingCart.splice(payload.index, 1, cart)

    const totalPrice = existingCart.reduce(
      (prev, cur) => cur.totalPrice + prev,
      0
    )

    localStorage.removeItem('cart')
    localStorage.setItem(
      'cart',
      JSON.stringify({
        ...state,
        cart: existingCart,
        grandTotal: totalPrice + state.taxes,
        discountAmount: payload.amount,
      })
    )

    return {
      ...state,
      cart: existingCart,
      grandTotal: totalPrice + state.taxes,
      discountAmount: payload.amount,
    }
  }

  if (type === 'REMOVE_DISCOUNT') {
    const cart = [...state.cart]

    const updatedCart = cart.map((el) => ({
      ...el,
      totalPrice: el.qty * el.price,
    }))

    localStorage.removeItem('cart')
    localStorage.setItem(
      'cart',
      JSON.stringify({
        ...state,
        cart: updatedCart,
        grandTotal: state.cart.reduce(
          (prev, cur) => cur.qty * cur.price + prev,
          0
        ),
      })
    )

    return {
      ...state,
      cart: updatedCart,
      grandTotal: state.cart.reduce(
        (prev, cur) => cur.qty * cur.price + prev,
        0
      ),
    }
  }

  if (type === 'PLAN') {
    localStorage.removeItem('cart')
    localStorage.setItem('cart', JSON.stringify({ ...state, plan: payload }))
    return { ...state, plan: payload }
  }

  if (type === 'STORE') {
    return payload
  }

  if (type === 'CHANGE_PLAN_PRICE') {
    localStorage.removeItem('cart')
    localStorage.setItem(
      'cart',
      JSON.stringify({
        ...state,
        planPrice: payload,
      })
    )
    return {
      ...state,
      planPrice: payload,
    }
  }

  if (type === 'RESET') {
    localStorage.removeItem('cart')
    return {
      cart: [],
      taxes: 0,
      grandTotal: 0,
      discountAmount: 0,
      plan: '',
      planPrice: 0,
    }
  }

  return state
}

export const CartContextProvider = ({ children }) => {
  const [state, dispatchFn] = useReducer(reducer, initState)

  const addToCart = (cart) => dispatchFn({ type: 'ADD', payload: cart })
  const removeFromCart = (id) => dispatchFn({ type: 'REMOVE', payload: id })
  const restoreCart = (data) => dispatchFn({ type: 'STORE', payload: data })
  const reduceDiscount = (discount) =>
    dispatchFn({ type: 'DISCOUNT', payload: discount })
  const removeDiscount = () => dispatchFn({ type: 'REMOVE_DISCOUNT' })
  const resetCart = () => dispatchFn({ type: 'RESET' })
  const addPlan = (plan) => dispatchFn({ type: 'PLAN', payload: plan })
  const changePlanPrice = (price) =>
    dispatchFn({ type: 'CHANGE_PLAN_PRICE', payload: price })

  const value = {
    ...state,
    addToCart,
    removeFromCart,
    restoreCart,
    reduceDiscount,
    removeDiscount,
    resetCart,
    addPlan,
    changePlanPrice,
  }

  return <CartContext.Provider value={ value }> { children } </CartContext.Provider>
}
