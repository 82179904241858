import React from "react";
import { FaComment, FaList, FaVideo } from "react-icons/fa";
import { IoBook } from "react-icons/io5";
import { Link } from "react-router-dom";
import CaseWrapper from "../wrappers/CaseWrapper";

const cards = [
  {
    id: 1,
    heading: "SCA Cases",
    icon: <FaList className="text-6xl" />,
    subHeading: `Introducing A4Medicine's Consultation Guides section:`,
    description: `Access 11 detailed guides for commonly tested, challenging presentations such as the 'angry patient' or managing multiple complaints with empathy. Ideal for honing your consultation skills for the SCA exam and beyond.`,
    to: "/sca-cover",
  },
  {
    id: 2,
    heading: "Consultation Guide",
    icon: <IoBook className="text-6xl" />,
    subHeading: `Introducing A4Medicine's SCA Exam Cases:`,
    description: `Dive into detailed role-play scenarios with comprehensive marking criteria and management guidelines, designed to enhance your exam preparation.`,
    to: "/consultation-guide",
  },
  {
    id: 3,
    heading: "Consultation Skills",
    icon: <FaComment className="text-6xl" />,
    subHeading: `Introducing A4Medicine's Consultation Skills:`,
    description: `Explore our comprehensive overview covering each marking domain of the exam, designed to enhance your understanding and mastery of crucial consultation skills.`,
    to: "/consultation-skills",
  },
  {
    id: 4,
    heading: "SCA Explanation",
    icon: <FaVideo className="text-6xl" />,
    subHeading: `Introducing A4Medicine's SCA Video Explanations:`,
    description: `Access our detailed video explanations for SCA exam cases, offering valuable insights and guidance to support your exam preparation journey.`,
    to: "/sca-explanation",
  },
];

const ScaScreen = () => {
  window.scrollTo(0, 0);
  return (
    <CaseWrapper heading="Case Revision Portal">
      <section className="max-[1160px]:px-10 max-sm:px-5">
        <ul className="w-[1150px] max-[1160px]:w-full mx-auto grid-cols-3 grid max-lg:grid-cols-2 gap-x-5 gap-y-6 max-md:grid-cols-1 pb-16 max-lg:pb-10 max-md:pb-5">
          {cards.map((crd) => (
            <li
              key={crd.id}
              className="w-full shadow-lg rounded-md bg-[#dfebff] flex flex-col items-center gap-5 py-8 px-9 text-[#16243b]"
            >
              {crd.icon}

              <h2 className="text-4xl font-bold">{crd.heading}</h2>

              <h4 className="text-xl font-bold text-black self-stretch mt-3">
                {crd.subHeading}
              </h4>

              <p className="text-black self-stretch -mt-3">{crd.description}</p>
              <Link
                to={crd.to}
                className="bg-[#213555] text-white py-4 px-6 rounded-md mt-auto"
              >
                Get Started
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </CaseWrapper>
  );
};

export default ScaScreen;
