import React, { useState } from 'react'
import { useParams, useLocation, Navigate } from 'react-router-dom'
import CaseWrapper from '../wrappers/CaseWrapper'
import { useFetch } from '../hooks/useFetch'
import Loading from '../components/Loading'

/**
 * 
 * doctor
examination
heading
id
management
marking
patient
subHeading

 */

const tabs = ['doctor', 'patient', 'marking', 'examination', 'management']

const ScaCaseDetailsScreen = () => {
  const id = useParams()?.id?.split('+')
  const [activeTab, setActiveTab] = useState(tabs[0])
  const { loading, data } = useFetch(`sca-case/${id[1]}`)
  const token = localStorage.getItem('token')
  const subscriptionId = localStorage.getItem('subscriptionId')
  const location = useLocation()

  if (!token || (token && subscriptionId && subscriptionId === 'none'))
    return <Navigate to='/subscription' state={{ from: location }} replace />

  const Case = data?.ScaCase || null

  return (
    <CaseWrapper heading={Case?.heading || 'Loading'}>
      {loading && <Loading color={'#213555'} visible={true} />}
      {!loading && Case && (
        <>
          <div className='flex w-full h-max mt-6 flex-wrap'>
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`flex-1 flex items-center justify-center text-center outline-none w-max py-8 text-xl font-bold capitalize border-b-4 px-10  ${
                  activeTab === tab
                    ? 'border-[#213555] text-[#213555]'
                    : 'border-[#ccd5e4]'
                }`}
                onClick={() => setActiveTab(tab)}>
                {tab}
              </button>
            ))}
          </div>

          <div className='max-lg:px-5 py-10'>
            <div
              className='w-[1024px] mx-auto max-lg:w-full'
              dangerouslySetInnerHTML={{ __html: Case[activeTab] }}
            />
          </div>
        </>
      )}
      {!loading && !Case && (
        <p className='col-span-3 text-center text-4xl font-bold'>
          OOPS!! No Data Found
        </p>
      )}
    </CaseWrapper>
  )
}

export default ScaCaseDetailsScreen
