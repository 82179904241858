import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import API from '../api'
import { BiCheck } from 'react-icons/bi'
import { BsPatchCheckFill } from 'react-icons/bs'

export const PlanExtensionScreen = () => {
  const [error, setError] = useState('')
  const [sending, setSending] = useState(false)
  const navigate = useNavigate()
  const [feedback, setFeedback] = useState(localStorage.getItem('reason') || '')
  const id = localStorage.getItem('id')
  const [isRequestedForPlanExtension, setIsRequestedForPlanExtension] =
    useState(true)

  const token = localStorage.getItem('token')
  const location = useLocation()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API}user/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        const data = await response.json()

        setIsRequestedForPlanExtension(
          data.data.user.isRequestedForPlanExtension
        )
      } catch (err) {
        console.error(err)
      }
    }

    fetchData()
  }, [token, id])

  const extendSubscription = async () => {
    if (feedback.length === 0) return toast.error("Reason can't be empty.")

    try {
      setSending(true)
      const body = JSON.stringify({
        isRequestedForPlanExtension: true,
        extensionRequestReason: feedback,
      })

      const res = await fetch(`${API}user/plan/extension/${id}`, {
        method: 'PATCH',
        body,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })

      await res.json()

      setIsRequestedForPlanExtension(true)

      toast.success(
        'Extension request generated successfully check email for updates'
      )
      navigate('/userprofile')
    } catch (err) {
      toast.error(err.message)
    } finally {
      setSending(false)
    }
  }

  if (!token) return <Navigate to='/login' state={{ from: location }} replace />
  const subscriptionId = localStorage.getItem('subscriptionId')
  return (
    <>
      <div className='h-full flex flex-col justify-center bg-[#E1E9FB] items-center pt-48 mt-8'>
      <div className='flex flex-col md:flex-row items-center justify-center'>
        {subscriptionId !== 'none' && (
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-5 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#9d9b9a]'>
            <Link to='/subscriptionPlan'>Subscription</Link>
          </button>
        )}
        <Link to='/userprofile'>
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-5 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#F17732]'>
            Edit Profile
          </button>
        </Link>
        <Link to='/customersupport'>
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-2 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#F17732]'>
            Customer Support
          </button>
        </Link>
        <Link to='/planextension'>
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-4 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#F17732]'>
            Extend Trial Pass
          </button>
        </Link>
        <Link to='/feedback'>
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-4 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#F17732]'>
            Feedback
          </button>
        </Link>
      </div>
        <div className='bg-white shadow-md rounded flex flex-col  gap-4 w-[90%] md:w-[50%] justify-center p-8 my-20'>
          {isRequestedForPlanExtension && (
            <h2 className='text-center text-4xl font-bold flex items-center gap-2 justify-center'>
              <BsPatchCheckFill className='text-green-500 text-5xl' /> Request
              Sent
            </h2>
          )}
          {!isRequestedForPlanExtension && (
            <>
              <h2 className='font-bold text-3xl'>Reason For Plan Extension</h2>

              <label htmlFor='feedback' className='flex flex-col gap-1'>
                <textarea
                  rows='2'
                  onChange={(e) => {
                    setFeedback(e.target.value)
                    setError('')
                  }}
                  value={feedback}
                  placeholder='User Feedback'
                  className='outline-none border p-2 resize-none border-gray-200 w-full rounded-md bg-gray-50'></textarea>
                {error.length > 0 && (
                  <span className='text-base font-semibold text-red-500'>
                    {error}
                  </span>
                )}
              </label>

              <button
                disabled={sending}
                onClick={extendSubscription}
                className='bg-[--main-color] self-start flex items-center justify-center text-white text-center font-[Poppins] px-4 cursor-pointer py-1 rounded hover:bg-[#F17732] disabled:bg-slate-400 disabled:cursor-not-allowed'>
                {sending ? (
                  <span className='feedbackLoader' />
                ) : (
                  'Submit Request'
                )}
              </button>
            </>
          )}
        </div>
      </div>
    </>
  )
}
