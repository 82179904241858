/* eslint-disable react-hooks/exhaustive-deps */
import './App.css'
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from './screens/Home'
import Login from './components/Login'
import Medical from './screens/Medical'
import Signup from './components/Signup'
import ChartDetail from './components/ChartDetail'
import ChartSubtopic from './components/ChartSubtopic'
import QuizDetail from './components/QuizDetail'
import Test from './components/Test'
import UserProfile from './screens/UserProfile'
import SubscriptionPlan from './components/SubscriptionPlan'
import ChartMainDetail from './components/ChartMainDetail'
// import Books from './screens/Books'
import CancerMedicineBook from './screens/CancerMedicineBook'
import VisualGuideBook from './screens/VisualGuideBook'

import DemoQuiz from './components/Demoquiz'
import Panelists from './screens/Panelists'
import PanelistCard from './components/PanelistCard'
import SubscriptionTaken from './components/Subscriptiontaken'
import ForgotPasswordPage from './components/ForgotPasswordPage'
import CustomerSupportPage from './screens/CustomerSupportPage'
import PrivacyPolicy from './components/PrivacyPolicy'
import SearchPage from './components/SearchPage'
import ErrorPage from './components/ErrorPage'
import { useContext, useEffect } from 'react'
import PastWebinars from './screens/PastWebinars'
import { Layout } from './Layout'
import { CartScreen } from './screens/CartScreen'
import { StripePage } from './components/StripePayment'
import { CartContext } from './context/CartContext'
import { useStateContext } from './context/StateContext'
import { SubscribePopup } from './components/SubscribePopup'
import { SuccesScreen } from './screens/SuccesScreen'
import { CancelScreen } from './screens/CancelScreen'
import PlanScreen from './screens/PlanScreen'
import { FeedbackScreen } from './screens/FeedbackScreen'
import { PlanExtensionScreen } from './screens/PlanExtensionScreen'
import { ExamScreen } from './screens/ExamScreen'
import SingleQuiz from './components/SingleQuiz'
import ScaScreen from './screens/ScaScreen'
import ConsultationSkillScreen from './screens/ConsultationSkillScreen'
import ConsultationGuideScreen from './screens/ConsultationGuideScreen'
import ScaExplanationScreen from './screens/ScaExplanationScreen'
import SCACoverScreen from './screens/SCACoverScreen'
import ScaCasesScreen from './screens/ScaCasesScreen'
import ScaCaseDetailsScreen from './screens/ScaCaseDetailsScreen'
import SkillsDetailsScreen from './screens/SkillsDetailsScrren'
import ExplanationsDetailsScreen from './screens/ExplanationDetailsScreen'
import GuideDetailsScreen from './screens/GuideDetailsScreen'
import BooksScreen from './screens/BooksScreen'

function App() {
  const { pathname } = useLocation()

  const ctx = useContext(CartContext)
  const sCtx = useStateContext()

  useEffect(() => {
    const cart = localStorage.getItem('cart')
    const token = localStorage.getItem('token')
    const subscription = localStorage.getItem('subscriptionId')

    cart && ctx.restoreCart(JSON.parse(cart))
    token && sCtx.setToken(token)
    subscription && sCtx.setSubscriptionId(subscription)
  }, [])

  return (
    <>
      { sCtx.token &&
        sCtx.subscriptionId &&
        sCtx.subscriptionId === 'none' &&
        !pathname.includes('subscription') && <SubscribePopup /> }
      <Routes>
        <Route path='/' element={ <Layout /> }>
          <Route path='/checkout' element={ <StripePage /> } />
          <Route path='/success' element={ <SuccesScreen /> } />
          <Route path='/cancel' element={ <CancelScreen /> } />
          <Route path='/' element={ <Home /> } />
          <Route path='/cart' element={ <CartScreen /> } />
          <Route path='/test' element={ <Test /> } />
          <Route path='/chart' element={ <Medical /> } />
          <Route path='/demoquiz' element={ <DemoQuiz /> } />
          <Route
            path='/cancer-medicine-book'
            element={ <CancerMedicineBook /> }
          />
          {/* <Route
            path='/books'
            element={sCtx.token ? <Books /> : <SubscriptionTaken />}
          /> */}
          <Route path='/book/:id' element={ <VisualGuideBook /> } />
          <Route path='/exam/:id' element={ <ExamScreen /> } />
          <Route path='/pannellist' element={ <Panelists /> } />
          <Route path='/pannellist/:id' element={ <PanelistCard /> } />
          <Route path='/books' element={ <BooksScreen /> } />
          <Route path='/webinar/:id' element={ <PastWebinars /> } />
          <Route path='/subscription' element={ <SubscriptionTaken /> } />
          <Route path='/section/latest/:id' element={ <ChartMainDetail /> } />
          <Route path='/chart/details/:id' element={ <ChartDetail /> } />
          <Route path='/chart/:id' element={ <ChartSubtopic /> } />
          <Route path='/quiz/:id' element={ <QuizDetail /> } />
          <Route
            path='/chartMain/:id'
            element={ sCtx.token ? <ChartMainDetail /> : <SubscriptionTaken /> }
          />
          <Route path='/login' element={ <Login /> } />
          <Route path='/signup' element={ <Signup /> } />
          <Route path='/forgotpassword' element={ <ForgotPasswordPage /> } />
          <Route path='/userprofile' element={ <UserProfile /> } />
          <Route path='/plans' element={ <PlanScreen /> } />
          <Route
            path='/subscriptionPlan'
            element={ sCtx.token ? <SubscriptionPlan /> : <Login /> }
          />
          <Route path='/customersupport' element={ <CustomerSupportPage /> } />
          <Route path='/feedback' element={ <FeedbackScreen /> } />
          <Route path='/planextension' element={ <PlanExtensionScreen /> } />
          <Route path='/privacy' element={ <PrivacyPolicy /> } />
          <Route path='/search' element={ <SearchPage /> } />
          <Route path='*' element={ <ErrorPage /> } />

          <Route path='/quiz/question/:id' element={ <SingleQuiz /> } />

          <Route path='/sca' element={ <ScaScreen /> } />
          <Route path='/sca-cover' element={ <SCACoverScreen /> } />
          <Route path='/sca-cover/:id' element={ <ScaCasesScreen /> } />
          <Route
            path='/consultation-skills'
            element={ <ConsultationSkillScreen /> }
          />
          <Route
            path='/consultation-skills/:id'
            element={ <SkillsDetailsScreen /> }
          />
          <Route
            path='/consultation-guide'
            element={ <ConsultationGuideScreen /> }
          />
          <Route
            path='/consultation-guide/:id'
            element={ <GuideDetailsScreen /> }
          />
          <Route path='/sca-explanation' element={ <ScaExplanationScreen /> } />
          <Route
            path='/sca-explanation/:id'
            element={ <ExplanationsDetailsScreen /> }
          />
          <Route path='/sca-case/:id' element={ <ScaCaseDetailsScreen /> } />
        </Route>
      </Routes>
    </>
  )
}

export default App
