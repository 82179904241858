import React from 'react'
import HomeComponent from '../components/HomeComponent'
import Webinar from '../components/Webinar'
import { webinarProf } from '../assets'
import { useParams } from 'react-router-dom'
import WebinarView from '../components/WebinarView'
import { useStateContext } from '../context/StateContext'

const PastWebinars = () => {
  const { id } = useParams()
  const { token } = useStateContext()

  if (id !== 'past' && id !== 'future' && token) return <WebinarView />

  return (
    <div className='mt-0'>
      <HomeComponent
        title='Learn From Experts A4 Webinar Series'
        subtitle='Our panel of specialists aim to bring expert knowledge and their experience.'
        image={webinarProf}
      />
      <Webinar />
    </div>
  )
}

export default PastWebinars
