import { useParams } from "react-router-dom";
import Quiz from "../components/Quiz";

export const ExamScreen = () => {
  const { id } = useParams();

  const examId = id.split("+").at(-1);
  const name = id.split("+")[0].replaceAll("_", " ");

  return (
    <div className="mt-36">
      <Quiz id={examId} name={name} prevLink={`/exam/${id}`} />
    </div>
  );
};
//
