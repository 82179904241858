/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import GoToTop from './components/GoToTop'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import API from './api'
import toast from 'react-hot-toast'
import { CartContext } from './context/CartContext'
import { useStateContext } from './context/StateContext'
import AppDownloadButton from './components/AppDownloadButton'

export const Layout = () => {
  const location = useLocation()
  const { setName, setToken, setSubscriptionId } = useStateContext()
  const ctx = useContext(CartContext)
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem('token') || ''
    if (!token) return
    const checkAuth = async () => {
      try {
        const res = await fetch(API + 'auth/verify-token', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (!res.ok) {
          // LOGOUT LOGIC
          toast.error('Session Expired')

          localStorage.clear()
          setName('')
          setToken('')
          setSubscriptionId('')
          ctx.resetCart()

          return navigate('/login')
        }

        const data = await res.json()

        if (!data.authenticated) {
          // LOGOUT LOGIC
          toast.error('Session Expired')

          localStorage.clear()
          setName('')
          setToken('')
          setSubscriptionId('')
          ctx.resetCart()

          return navigate('/login')
        }
      } catch (err) {
        console.error(err)
      }
    }

    checkAuth()
  }, [location])

  return (
    <>
      <AppDownloadButton />
      <GoToTop />
      <Navbar />
      <Outlet />
      <Footer />
    </>
  )
}
