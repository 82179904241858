import React, { useContext, useEffect } from 'react'
import { CartContext } from '../context/CartContext'
import { useNavigate } from 'react-router-dom'

export const SuccesScreen = () => {
  const ctx = useContext(CartContext)
  const navigate = useNavigate()

  useEffect(() => {
    ctx.resetCart()

    setTimeout(() => {
      navigate('/')
    }, 5000)
  }, [])

  return (
    <>
      <div className='mt-[200px]'>
        <h2 className='text-center font-semibold text-6xl mt-5 text-sky-800'>
          Order Placed Successfully
        </h2>

        <p className='text-lg font-bold text-center mt-5 mb-10'>
          Please check your mail for more information
        </p>
      </div>
    </>
  )
}
