import React from 'react'
import CaseWrapper from '../wrappers/CaseWrapper'
import { useFetch } from '../hooks/useFetch'
import { useLocation, Navigate, Link } from 'react-router-dom'
import Loading from '../components/Loading'
import { replaceSpaces } from '../utility'

const ConsultationSkillScreen = () => {
  window.scrollTo(0, 0)
  const { data, loading } = useFetch('sca-consultation-skill')
  const token = localStorage.getItem('token')
  const subscriptionId = localStorage.getItem('subscriptionId')
  const location = useLocation()

  if (!token || (token && subscriptionId && subscriptionId === 'none'))
    return <Navigate to='/subscription' state={{ from: location }} replace />

  const skills = data?.ScaConsultationSkill || []

  return (
    <CaseWrapper heading='Consultation Skills'>
      {loading && <Loading color={'#213555'} visible={true} />}
      <section className='max-[1160px]:px-10 max-sm:px-5'>
        <ul className='w-[1150px] max-[1160px]:w-full mx-auto grid-cols-3 grid max-lg:grid-cols-2 gap-x-5 gap-y-6 max-md:grid-cols-1 pb-16 max-lg:pb-10 max-md:pb-5'>
          {!loading &&
            skills.map((skill) => (
              <li
                key={skill.id}
                className='w-full shadow-lg rounded-md bg-[#dfebff] flex flex-col gap-4 p-5 text-[#16243b]'>
                {skill?.image && (
                  <img
                    src={`https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${skill.image}`}
                    alt={`${skill.heading}`}
                    className='w-full aspect-square object-cover object-center'
                  />
                )}

                {!skill?.image && (
                  <div
                    className='w-[90%] aspect-square overflow-hidden p-2 border overflow-y-scroll overflow-x-scroll cus_scrollBar'
                    dangerouslySetInnerHTML={{ __html: skill.description }}
                  />
                )}
                <h2 className='text-4xl font-bold'>{skill.heading}</h2>

                <Link
                  to={`${replaceSpaces(skill.heading, '`')}+${skill.id}`}
                  className='bg-[#213555] text-white py-4 px-6 self-center rounded-md mt-auto'>
                  Know More
                </Link>
              </li>
            ))}

          {!loading && skills.length === 0 && (
            <p className='col-span-3 text-center text-4xl font-bold'>
              OOPS!! No Skills Found
            </p>
          )}
        </ul>
      </section>
    </CaseWrapper>
  )
}

export default ConsultationSkillScreen
