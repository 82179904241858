import React from 'react'
import CaseWrapper from '../wrappers/CaseWrapper'
import { useFetch } from '../hooks/useFetch'
import { useLocation, Navigate, Link } from 'react-router-dom'
import Loading from '../components/Loading'
import { replaceSpaces } from '../utility'

const ConsultationGuideScreen = () => {
  window.scrollTo(0, 0)
  const { data, loading } = useFetch('sca-consultation-guide')
  const token = localStorage.getItem('token')
  const subscriptionId = localStorage.getItem('subscriptionId')
  const location = useLocation()

  if (!token || (token && subscriptionId && subscriptionId === 'none'))
    return <Navigate to='/subscription' state={{ from: location }} replace />

  const guides = data?.ScaConsultation || []

  return (
    <CaseWrapper heading='Consultation Guides'>
      {loading && <Loading color={'#213555'} visible={true} />}
      <section className='max-[1160px]:px-10 max-sm:px-5'>
        <ul className='w-[1150px] max-[1160px]:w-full mx-auto grid-cols-3 grid max-lg:grid-cols-2 gap-x-5 gap-y-6 max-md:grid-cols-1 pb-16 max-lg:pb-10 max-md:pb-5'>
          {guides.map((guide) => (
            <li
              key={guide.id}
              className='w-full shadow-lg rounded-md bg-[#dfebff] flex flex-col gap-4 p-5 text-[#16243b]'>
              {guide?.image && (
                <img
                  src={`https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${guide.image}`}
                  alt={`${guide.heading}`}
                  className='w-full aspect-square object-cover object-center'
                />
              )}

              {!guide?.image && (
                <div
                  className='w-[90%] aspect-square overflow-hidden p-2 border overflow-y-scroll overflow-x-scroll cus_scrollBar'
                  dangerouslySetInnerHTML={{ __html: guide.description }}
                />
              )}
              <h2 className='text-4xl font-bold'>{guide.heading}</h2>

              <Link
                to={`${replaceSpaces(guide.heading, '`')}+${guide.id}`}
                className='bg-[#213555] text-white py-4 px-6 self-center rounded-md mt-auto'>
                Know More
              </Link>
            </li>
          ))}
          {!loading && guides.length === 0 && (
            <p className='col-span-3 text-center text-4xl font-bold'>
              OOPS!! No Guides Found
            </p>
          )}
        </ul>
      </section>
    </CaseWrapper>
  )
}

export default ConsultationGuideScreen
