import React, { useState, useEffect } from 'react'
import WebinarCard from './WebinarCard'
import Loading from './Loading'
import API from '../api'
import { useParams } from 'react-router-dom'

const Webinar = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { id } = useParams()

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const response = await fetch(`${API}webinar`)
      const dat = await response.json()

      const webinars = dat?.data.webinars

      const filteredWeb = webinars.filter((el) => {
        if (id === 'past') return el.isPastWebinar && el
        if (id === 'future') return !el.isPastWebinar && el
        return false
      })

      setData(filteredWeb)
      setLoading(false)
    }
    getData()
  }, [id])

  return (
    <div className='bg-[#E1E9FB]'>
      <h1 className='text-3xl py-4 px-[8%] flex items-center justify-center font-bold text-center text-[--main-color] md:px-[20%]'>
        Unlock the knowledge of renowned specialists at your fingertips with our
        Primary Care Webinars On Demand.
      </h1>
      <hr className='bg-gray-300 h-[1.2px] grid grid-cols-2  mx-24 border-dashed' />
      <div className='grid grid-cols-1 items-center justify-center'>
        {loading && <Loading visible={true} color='#0058AB' />}
        {!loading &&
          data.length > 0 &&
          data.map((web) => {
            return (
              <div className='flex items-center justify-center' key={web.id}>
                <WebinarCard
                  title={web.topic}
                  description={web.description}
                  image={`https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${web.image}`}
                  speaker={web.speaker.name}
                  date={web.date}
                  time={web.time}
                  id={web.id}
                />
              </div>
            )
          })}
        {!loading && data.length === 0 && (
          <h2 className='text-center my-10 font-semibold text-4xl'>
            No Webinars Found!!
          </h2>
        )}
      </div>
    </div>
  )
}

export default Webinar
