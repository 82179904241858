import React from "react";
import { MdWarning } from "react-icons/md";
import { Link } from "react-router-dom";

export const SubscribePopup = () => {
  return (
    <>
      <div className="py-2 px-4 fixed bottom-8 bg-slate-100 flex items-center gap-2 animate-bounce shadow-md left-2 z-[2000] rounded w-max text-lg">
        <MdWarning className="text-orange-500 text-3xl" /> Please Choose a Plan
        and Complete Your Subscription{" "}
        <Link
          to="/userprofile"
          className="text-sky-900 font-black hover:border-b-2 border-sky-900 capitalize"
        >
          click here
        </Link>
      </div>
    </>
  );
};
