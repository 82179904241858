import React from 'react'
import CaseWrapper from '../wrappers/CaseWrapper'
import { useFetch } from '../hooks/useFetch'
import { useLocation, Navigate, Link } from 'react-router-dom'
import Loading from '../components/Loading'
import { replaceSpaces } from '../utility'

const ScaExplanationScreen = () => {
  window.scrollTo(0, 0)
  const { data, loading } = useFetch('sca-explanation')
  const token = localStorage.getItem('token')
  const subscriptionId = localStorage.getItem('subscriptionId')
  const location = useLocation()

  if (!token || (token && subscriptionId && subscriptionId === 'none'))
    return <Navigate to='/subscription' state={{ from: location }} replace />

  const explanations = data?.ScaExplanation || []

  return (
    <CaseWrapper heading='SCA Explanations'>
      {loading && <Loading color={'#213555'} visible={true} />}
      <section className='max-[1160px]:px-10 max-sm:px-5'>
        <ul className='w-[1150px] max-[1160px]:w-full mx-auto grid-cols-3 grid max-lg:grid-cols-2 gap-x-5 gap-y-6 max-md:grid-cols-1 pb-16 max-lg:pb-10 max-md:pb-5'>
          {!loading &&
            explanations.map((explanation) => (
              <li
                key={explanation.id}
                className='w-full shadow-lg rounded-md bg-[#e4e7eb] flex flex-col gap-4 p-5 text-[#16243b]'>
                <div
                  className='align w-full aspect-video'
                  dangerouslySetInnerHTML={{ __html: explanation.videoUrl }}
                />
                <h2 className='text-4xl font-bold'>{explanation.heading}</h2>

                <Link
                  to={`${replaceSpaces(explanation.heading, '`')}+${
                    explanation.id
                  }`}
                  className='bg-[#213555] text-white py-4 px-6 self-center rounded-md mt-auto'>
                  Know More
                </Link>
              </li>
            ))}
          {!loading && explanations.length === 0 && (
            <p className='col-span-3 text-center text-4xl font-bold'>
              OOPS!! No Explantions Found
            </p>
          )}
        </ul>
      </section>
    </CaseWrapper>
  )
}

export default ScaExplanationScreen
