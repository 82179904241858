import { Link, useLocation, Navigate } from 'react-router-dom'
import CaseWrapper from '../wrappers/CaseWrapper'
import { useFetch } from '../hooks/useFetch'
import { replaceSpaces } from '../utility'
import Loading from '../components/Loading'

const SCACoverScreen = () => {
  window.scrollTo(0, 0)
  const { loading, data } = useFetch('sca-cover')
  const token = localStorage.getItem('token')
  const subscriptionId = localStorage.getItem('subscriptionId')
  const location = useLocation()

  if (!token || (token && subscriptionId && subscriptionId === 'none'))
    return <Navigate to='/subscription' state={{ from: location }} replace />

  const covers = data?.ScaCovers || []

  return (
    <CaseWrapper heading='Welcome to the Cases'>
      {loading && <Loading color={'#213555'} visible={true} />}
      <section className='max-[1160px]:px-10 max-sm:px-5'>
        <ul className='w-[1150px] max-[1160px]:w-full mx-auto grid-cols-3 grid max-lg:grid-cols-2 gap-x-5 gap-y-6 max-md:grid-cols-1 pb-16 max-lg:pb-10 max-md:pb-5'>
          {!loading &&
            covers.map((cover) => (
              <li
                key={cover.id}
                className='w-full shadow-lg rounded-md bg-[#dfebff] flex flex-col items-center gap-4 p-5 text-[#16243b]'>
                {cover?.image && (
                  <img
                    src={`https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${cover.image}`}
                    alt={`${cover.heading}`}
                    className='w-full aspect-square object-cover object-center'
                  />
                )}
                <h2 className='text-4xl font-bold'>{cover.heading}</h2>
                <p className='text-center text-lg font-semibold -mt-4'>
                  ({cover.case.length}) Cases
                </p>
                <Link
                  to={`${replaceSpaces(cover.heading, '`')}+${cover.id}`}
                  className='bg-[#213555] text-white py-4 px-6 rounded-md mt-auto'>
                  Know More
                </Link>
              </li>
            ))}
          {!loading && covers.length === 0 && (
            <p className='col-span-3 text-center text-4xl font-bold'>
              OOPS!! No Covers Found
            </p>
          )}
        </ul>
      </section>
    </CaseWrapper>
  )
}

export default SCACoverScreen
