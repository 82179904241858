/* eslint-disable react-hooks/exhaustive-deps */
import toast from 'react-hot-toast'
import { useStateContext } from './context/StateContext'
import API from './api'
import { useEffect, useState } from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'

export const replaceSpaces = (value, replacer) =>
  value.replaceAll(' ', replacer)

export const ExtendSubscriptionButton = () => {
  const { token, subscriptionId } = useStateContext()
  const id = localStorage.getItem('id')
  const [submitting, setSubmitting] = useState(false)
  const [isRequestedForPlanExtension, setIsRequestedForPlanExtension] =
    useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API}user/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        const data = await response.json()

        setIsRequestedForPlanExtension(
          data.data.user.isRequestedForPlanExtension
        )
      } catch (err) {
        console.error(err)
      }
    }

    fetchData()
  }, [])

  const extendSubscription = async () => {
    try {
      setSubmitting(true)
      const body = JSON.stringify({ isRequestedForPlanExtension: true })

      const res = await fetch(`${API}user/plan/extension/${id}`, {
        method: 'PATCH',
        body,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })

      await res.json()

      setSubmitting(false)
      setIsRequestedForPlanExtension(true)

      toast.success(
        'Extension request generated successfully check email for updates'
      )
    } catch (err) {
      toast.error(err.message)
    }
  }

  if (!token && !subscriptionId) return <></>

  if (token && subscriptionId && subscriptionId === 'none') return <></>

  return (
    <button
      disabled={isRequestedForPlanExtension || submitting}
      onClick={extendSubscription}
      className='bg-[--main-color]  text-xl flex items-center justify-center text-white text-center font-[Poppins] px-4 cursor-pointer capitalize py-2 rounded md:ml-4 hover:bg-[#F17732] disabled:bg-gray-500 disabled:text-gray-50 disabled:cursor-not-allowed'>
      {submitting && 'submitting...'}
      {isRequestedForPlanExtension && 'Request Sent'}
      {!submitting && !isRequestedForPlanExtension && 'Extend Trial Pass'}
    </button>
  )
}

export const displayStars = (num, func) => {
  const starCount = num.toString()
  const stars = []

  for (let i = 0; i < +starCount[0]; i++)
    stars.push(
      <AiFillStar
        className={`text-orange-400 ${func && 'cursor-pointer'}`}
        key={i}
        id={`${i + 1}`}
        onClick={func}
      />
    )

  while (stars.length !== 5) {
    stars.push(
      <AiOutlineStar
        className={`text-gray-400 ${func && 'cursor-pointer'}`}
        id={`${stars.length + 1}`}
        key={Math.random()}
        onClick={func}
      />
    )
  }

  return stars
}

export const generateLink = (type, name, id) => {
  let link = ''
  switch (type) {
    case 'book':
      link = `/book/${replaceSpaces(name, '')}+${id}`
      break

    case 'section':
      link = `/chart/details/${replaceSpaces(name, '')}+${id}`
      break

    case 'quiz':
      link = `/quiz/${replaceSpaces(name, '')}+${id}`
      break

    case 'pannellist':
      link = `/pannellist/${replaceSpaces(name, '')}+${id}`
      break

    case 'webinar':
      link = `/webinar/${replaceSpaces(name, '')}+${id}`
      break

    case 'chapter':
      link = `/quiz/${replaceSpaces(name, '')}+${id}`
      break

    case 'question':
      link = `/quiz/question/${replaceSpaces(name.slice(0, -1), '')}+${id}`
      break

    default:
      return ''
  }

  return link
}
