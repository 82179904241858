import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { LuLoader } from "react-icons/lu";
import API from "../api";
import { Link, useNavigate } from "react-router-dom";
import { generateLink } from "../utility";
import { useStateContext } from "../context/StateContext";

let debounce;

const HomePageSearchBar = () => {
  const [searching, setSearching] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const { setSavedResult } = useStateContext();

  const searchHandler = (e) => {
    clearTimeout(debounce);
    setSearchStr(e.target.value);

    if (!e.target.value.trim()) {
      setSearching(false);
      setSearchResults([]);
      return;
    }

    setSearching(true);
    const value = e.target.value;

    debounce = setTimeout(async () => {
      try {
        const response = await fetch(`${API}/search/?search=${value}`);
        const data = await response.json();

        if (data.status !== "success") throw new Error("Something went wrong.");

        setSearchResults(data.data.searchResults);
        setSavedResult(data.data.searchResults);
        setSearching(false);
      } catch (err) {
        console.error(err);
        setSearching(false);
      }
    }, 400);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (searchResults.length === 0) return;

    navigate("/search");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    searchStr.length === 0 && setSearchResults([]);
  }, [searchStr]);

  return (
    <div className="w-full flex max-lg:hidden flex-col items-center py-16 bg-[#d9d9d9] gap-10">
      <h3 className="text-sky-900 w-[600px] font-bold text-3xl text-center">
        Find It Fast on A4Medicine: Charts, Tables, MCQs, and Webinars for
        Medical Mastery!
      </h3>

      <form action="#" className="relative" onSubmit={submitHandler}>
        <div className="flex w-[504px] rounded-md overflow-hidden h-[50px]">
          <input
            type="search"
            onChange={searchHandler}
            className="bg-[#ffff] border-transparent focus:border-gray-400 outline-none rounded-l-md block w-full h-full"
            placeholder="Start Typing..."
          />
          <button
            type="submit"
            className="outline-none rounded-r-md rounded-l-none px-5 flex items-center justify-center text-white bg-[#0972cd] w-max h-full"
          >
            <FaSearch />
          </button>
        </div>

        <ul className="absolute w-full top-[105] rounded-md left-0 shadow-md bg-white flex flex-col max-h-[300px] overflow-y-scroll">
          {searching && (
            <li className="py-4 flex items-center justify-center gap-3">
              <LuLoader className="animate-spin" /> Searching...
            </li>
          )}
          {searchStr && !searching && searchResults.length === 0 && (
            <li>No Data Found!!</li>
          )}
          {!searching &&
            searchResults.length > 0 &&
            searchResults.map(
              (el) =>
                !el?.name?.includes("<") && (
                  <li key={el._id}>
                    <Link
                      to={generateLink(el.type, el?.name || "", el._id)}
                      onClick={() => window.scrollTo(0, 0)}
                      className="px-3 py-3 block 
                      transition-all hover:bg-gray-100 hover:text-violet-700 border-b"
                    >
                      {el.name}
                    </Link>
                  </li>
                )
            )}
        </ul>
      </form>
    </div>
  );
};

export default HomePageSearchBar;
