import React from 'react'
import { Link } from 'react-router-dom'
import { useStateContext } from '../context/StateContext'
import scrollToTop from './scrollToTop'
import { generateLink } from '../utility'
import HTMLRenderer from './InnerHtml'
import { Fragment } from 'react'

const SearchResult = ({ result }) => {
  const handleClick = () => {
    scrollToTop()
  }

  return (
    <>
      <li className='my-2 hover:scale-105 text-start transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none'>
        <Link
          to={generateLink(result.type, result.name || '', result._id)}
          onClick={handleClick}
          state={{ id: result._id }}
          className='text-[--main-color] md:text-2xl text-lg font-bold capitalize flex items-center'>
          <span className='rounded-full h-6 w-6  mr-2 flex items-start justify-center'>
            <i className='fas fa-circle  text-xs'></i>
          </span>
          <HTMLRenderer htmlString={result.name} />
        </Link>
      </li>
    </>
  )
}

const SearchPage = () => {
  const { savedResult } = useStateContext()

  return (
    <div className=' bg-[#E1E9FB] md:p-12 p-4 md:mt-20 mt-40'>
      <div className='flex justify-start items-start mt-28 bg-white p-8 rounded-lg'>
        <div className=' '>
          {savedResult?.length > 0 && (
            <ul className='w-[90%]'>
              {savedResult.map((result, i) => (
                <Fragment key={i}>
                  <SearchResult result={result} />
                  <div className='bg-gray-300 h-[1px]' />
                </Fragment>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default SearchPage
