import {
  AddressElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useContext } from "react";
import { CartContext } from "../context/CartContext";

const stripePromise = loadStripe(
  "pk_live_51I309yHQ9sXTdXVMdwrezTJNYCeRYGbYdItXsdBk8MQNYAsWDe1xNxI0e0Ys6APdStMDkZVOBMJYQYWrOpaXmmCt00yjbpr2ks"
);

// const stripePromise = loadStripe(
//   "pk_test_51I309yHQ9sXTdXVMEKECla38URK6fhl1hbrb3AsXUsLwNI7OucLdeddC49JrhkTVLLkwEEgLIyIo2IW4NFEeXsdm00W1OT5q3s"
// );

export const StripePage = () => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "setup",
        currency: "gbp",
      }}
    >
      <CheckoutForm />
    </Elements>
  );
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const ctx = useContext(CartContext);
  const elements = useElements();
  const discount = ctx.cart.reduce((prev, el) => el.discountAmount + prev, 0);
  const plan = ctx.plan;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    const address = await elements.getElement("address").getValue();

    if (address.complete) {
      const line_items = [];
      for (let i = 0; i < ctx.cart.length; i++) {
        const obj = {
          price_data: {
            currency: "gbp",
            product_data: {
              name: ctx.cart[i].name,
              description: "Your Order",
              images: [ctx.cart[i].image],
            },
            unit_amount: Math.ceil(
              ((ctx.cart[i].totalPrice - ctx.cart[i].discountAmount) /
                ctx.cart[i].qty) *
                100
            ),
          },
          quantity: ctx.cart[i].qty,
        };

        line_items.push(obj);
      }

      const body = JSON.stringify({
        metadata: {
          type: "book",
          line1: address.value.address.line1,
          line2: address.value.address.line2 || "dummy",
          city: address.value.address.city,
          state: address.value.address.state || "dummy",
          country: address.value.address.country,
          pinCode: address.value.address.postal_code,
          product: JSON.stringify(
            ctx.cart.map((el) => ({
              id: el.id,
              name: el.name,
              price: el.price,
              qty: el.qty,
              totalPrice: el.totalPrice,
              discountAmount: el.discountAmount,
            }))
          ),
          plan,
          planPrice: ctx.planPrice * 100,
          discount: discount > 0,
          domain: "com",
        },
        line_items,
      });

      // console.log(body)

      // return ''
      const res = await fetch(
        "https://a4medicine.co.uk/api/v1/stripe/checkout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await res.json();

      await stripe.redirectToCheckout({
        sessionId: data.data.id,
      });
    }
  };

  return (
    <div className="w-[70%] m-auto my-10 mt-[200px]">
      <h2 className="text-center text-5xl font-semibold mb-10">Checkout </h2>
      <form onSubmit={handleSubmit}>
        <AddressElement
          options={{
            mode: "shipping",
            fields: {
              phone: "always",
            },
          }}
        />

        <button
          type="submit"
          disabled={!stripe}
          className="px-6 py-[.4rem] mt-5 text-[17px] bg-sky-900 text-white rounded disabled:bg-slate-100 disabled:text-slate-500"
        >
          Procced to Pay
        </button>
      </form>
    </div>
  );
};

// [
//   name: String,
//   images: String[],
//   quantity: String,
//   unit_amount: Number,
//   metaData: String
// ]
// PRICE * 981.54
// medaData.address = {}

// {
//   "metadata": {
//     "address": {
//       "line1": "phoolwadi",
//       "line2": "chunar",
//       "city": "ex",
//       "state": "Up",
//       "country": "ex",
//       "pinCode": "231304"
//     }
//   },
//   "line_items": [
//     {
//       "price_data": {
//         "currency": "gbp",
//         "product_data": {
//           "name": "ex",
//           "description": "ex",
//           "images": ["ex"] // Use an array for images
//         },
//         "unit_amount": 1000

//       },
//       "quantity": 12
//     }
//   ]
// }
