import React, { useEffect, useState } from "react";
import SkeletonCard from "./SkeletonCard";
import { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";
import scrollToTop from "./scrollToTop";
import "./Quiz.css";
import { useStateContext } from "../context/StateContext";
import { replaceSpaces } from "../utility";

// attempted: 1
// quizId: '64f623f83dcfb1fed79b93d0'
// unattempted

const Quiz = ({ id, name, prevLink }) => {
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token, subscriptionId } = useStateContext();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        // v2
        const response = await fetch(
          `https://a4medicine.co.uk/api/v1/quiz/exam/${id}`
        );
        const dat = await response.json();

        const sortedData = [...dat?.data?.quiz].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        if (dat.status === "success") {
          setQuizes(sortedData);
          setLoading(false);
        } else {
        }
      } catch (error) {
        // console.error(error)
      }
    };

    const getDataWithHistory = async () => {
      setLoading(true);
      try {
        // /v2
        const quizResponse = await fetch(
          `https://a4medicine.co.uk/api/v1/quiz/exam/${id}`
        );
        const quizData = await quizResponse.json();

        const sortedData = [...quizData?.data?.quiz].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        // v2
        const historyResponse = await fetch(
          "https://a4medicine.co.uk/api/v1/progress/list",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const historyData = await historyResponse.json();

        if (historyData.status !== "success") return;

        const history = historyData.data;

        const quizWithHistory = sortedData.map((el) => {
          const newQuiz = { ...el };
          newQuiz.attempted = 0;
          newQuiz.unattempted = el.questions.length;

          if (history.some((his) => his.quizId === newQuiz.id)) {
            const index = history.findIndex((his) => his.quizId === newQuiz.id);

            newQuiz.attempted = history[index].attempted;
            newQuiz.unattempted = history[index].unattempted;
          }

          return newQuiz;
        });

        setQuizes(quizWithHistory);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    window.scrollTo(0, 0);
    token && subscriptionId !== "none" ? getDataWithHistory() : getData();
  }, [token, subscriptionId, id]);

  let totalQuizes = 0,
    totalQuesUnattempted = 0,
    totalQuesAttempted = 0;

  if (quizes.length > 0)
    quizes.forEach((el) => {
      totalQuizes += el.questions.length;
      totalQuesAttempted += el.attempted;
      totalQuesUnattempted += el.unattempted;
    });

  return (
    <div className="py-4 m-[-12px] bg-[#E1E9FB] flex flex-col justify-center items-center text-center">
      <div className="container">
        <div className="my-8 section-title md:my-5">
          <h2 className="flex text-2xl justify-center align-center font-bold text-center md:text-5xl">
            <span className="text-3xl md:text-5xl text-[color]">{name}</span>
          </h2>
        </div>
        <div className="flex items-center flex-col justify-center px-6 md:px-12">
          <div class="table-container m-2 px-2 mb-8">
            <h1 class="text-4xl font-bold mb-6">Quiz Index</h1>
            <table class="custom-table">
              <thead>
                <tr>
                  <th class="table-header">Name</th>
                  <th class="table-header">Count</th>
                  {token && subscriptionId !== "none" && (
                    <>
                      <th class="table-header">Questions Attempted</th>
                      <th class="table-header">Questions Un Attempted</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {quizes.map((quiz) => (
                  <tr key={quiz.id}>
                    <td>
                      <Link
                        to={`/quiz/${replaceSpaces(quiz.name, "_")}+${quiz.id}`}
                        state={{ prevLink: prevLink }}
                        className="hover:underline hover:text-violet-600"
                      >
                        {quiz.name}
                      </Link>
                    </td>
                    <td>{quiz?.questions.length}</td>
                    {token && subscriptionId !== "none" && (
                      <>
                        <td>{quiz?.attempted}</td>
                        <td>{quiz?.unattempted}</td>
                      </>
                    )}
                  </tr>
                ))}
                {token && subscriptionId !== "none" && (
                  <tr className="font-semibold">
                    <td>Total</td>
                    <td>{totalQuizes}</td>

                    <td>{totalQuesAttempted}</td>
                    <td>{totalQuesUnattempted}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <ul className="grid gap-y-8 lg:grid-cols-3  gap-y-12 gap-x-20 md:grid-cols-2 gap-x-8">
            <SkeletonTheme highlightColor="#e8ffd1">
              {loading ? (
                <>
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                </>
              ) : (
                quizes.map((quiz) => {
                  return (
                    <li
                      key={quiz.id}
                      className="flex  bg-white flex-col gap-3 rounded-lg shadow-xl mx-10 p-2 w-70 justify-center items-center text-center"
                    >
                      <img
                        src={`https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${quiz.image}`}
                        alt=""
                        className="w-[150px] h-[150px] object-contain rounded-full object-center"
                      />

                      <div className="flex items-center flex-col">
                        <h4 className="text-[1.2rem] font-semibold">
                          {quiz.name}
                        </h4>
                        <p className="text-center text-base text-slate-500">
                          {quiz?.questions.length} question
                          {quiz?.questions.length > 1 ? "s" : ""}
                        </p>
                      </div>

                      <Link
                        to={`/quiz/${replaceSpaces(quiz.name, "_")}+${quiz.id}`}
                        state={{ prevLink: prevLink }}
                        className="bg-[--main-color] h-11 w-32  mb-6 text-center text-white rounded-md pt-2"
                        onClick={scrollToTop}
                      >
                        Start Questions
                      </Link>
                    </li>
                  );
                })
              )}
            </SkeletonTheme>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
