import React, { useState } from "react";
import bgImg from "../assets/bgmain.png";
import PopFeature from "./Popfeature";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const desc = `- At A4Medicine, we are committed to empowering primary care
  clinicians on their path to exam success and safe practice. We
  understand the challenges that GP trainees face while preparing for
  the RCGP AKT exam, and we're here to help you overcome them.
  What sets A4Medicine apart is our comprehensive study material,
  carefully designed to simplify complex concepts. We offer a unique
  blend of charts, webinars, and resources that cater specifically to
  the theory part of the exam. Our charts provide visually appealing and
  concise summaries, making it easier for candidates to grasp essential
  information effectively.
  In addition to our study material, we provide an extensive collection
  of MCQs tailored to the RCGP AKT exam format. By practicing these MCQs
  after studying the relevant material on our website, you will develop
  a solid foundation of knowledge and be well-prepared to tackle the
  exam confidently. Our MCQs cover a wide range of topics and are
  designed to enhance your understanding, critical thinking skills, and
  exam technique.
  Prepare with us and unlock your full potential on the path to exam
  success and safe practice.`;

  const handleClick = () => {
    setIsOpen(true);
  };
  const handleOnClose = () => {
    setIsOpen(false);
  };
  return (
    <div className="h-full pt-36 w-full bg-[#E1E9FB] flex flex-col justify-between">
      <div className="grid md:grid-cols-2 max-w-[1240px] m-auto">
        <div className="flex flex-col justify-center md:items-start w-full px-2 py-12">
          <div className="effect-wrap ">
            <i className="fas fa-plus text-[#EFFF10] text-3xl animate-spin-slow absolute z-10 left-[10%] top-[20%]"></i>
            <i className="fas fa-plus text-[orange] animate-spin-slow text-3xl absolute z-10 left-[90%] bottom-[30%]"></i>
            {/* <i className="fas fa-circle-notch text-[orange] animate-spin-slow text-3xl absolute z-10 left-[10%] bottom-[10%]"></i> */}
            {/* <img src={healthcare} alt="" className='h-24 absolute left-[60%] md:left-[50%] top-[84%] md:top-[80%]  animate-pulse' /> */}
          </div>
          <p className="text-xl p-2 md:p-5">
            "A4Medicine: Your comprehensive, fully referenced educational
            resource for primary care clinicians worldwide."
          </p>
          <h1 className="py-3 uppercase text-[#112653]   p-2 md:p-5 text-3xl md:text-5xl font-bold">
            Your Primary Care Knowledge Hub
          </h1>
          <p className="text-2xl font-bold  p-2 md:p-5">
            A4Medicine your path to primary care <br /> exams success and safe
            practice!.
          </p>
          <div className="flex gap-10 w-92 m-5 md:ml-5  ml-2 ">
            <button
              className="h-12 text-center pt-1 px-2 text-white hover:bg-[#F17732] text-2xl  bg-[--main-color] "
              onClick={handleClick}
            >
              Read More
            </button>
            <button
              className="h-12   text-center pt-1 px-2 text-white hover:bg-[#F17732] text-2xl  bg-[--main-color] "
              onClick={()=>navigate("/signup")}
            >
              Free Trial
            </button>

          </div>
          {isOpen && (
            <PopFeature onClose={handleOnClose} visible={isOpen} desc={desc} />
          )}
        </div>
        <div>
          <img
            loading="lazy"
            src={bgImg}
            alt=""
            className="w-full h-[380px] md:h-[520px]"
          />
        </div>
      </div>
      {/* <div className="absolute flex text-2xl top-[60%] right-[8%] md:top-[22%] md:right-[10%] z-20 shadow-md text-center bg-white rounded-md px-4 py-4">
        <h5>Prepare</h5>
        <span className="w-8 h-6 m-auto flex items-center justify-center">➡️</span>
        </div>
        <div className="absolute flex text-2xl top-[100%] left-[4%] md:top-[60%] md:left-[44%]   z-20 shadow-md text-center bg-white rounded-md px-4 py-4">
        <h5>Practice</h5>
        <span className="w-8 h-6 m-auto flex items-center justify-center"></span>
        </div>
      <div className="absolute flex text-2xl top-[120%] right-[2%] md:top-[80%] md:right-[2%]  z-20 shadow-md text-center bg-white rounded-md px-4 py-4">
        <h5>Pass</h5>
        <span className="w-8 h-6 m-auto flex items-center justify-center">✅</span>
      </div>       */}
    </div>
  );
};

export default Hero;
