/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from '../context/StateContext'
import Loading from './Loading'
import {
  MdCancel,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdWarning,
} from 'react-icons/md'
import {
  BiDislike,
  BiLike,
  BiSolidDislike,
  BiSolidLeftArrow,
  BiSolidLike,
} from 'react-icons/bi'
import { BsCheckCircleFill, BsFillFastForwardFill } from 'react-icons/bs'
import { ImCancelCircle } from 'react-icons/im'
import { AnimatePresence, motion as m } from 'framer-motion'
import axios from 'axios'
import toast from 'react-hot-toast'
import HTMLRenderer from './InnerHtml'
import { replaceSpaces } from '../utility'

/*
* id: string
* image: string
* name: string
* questions: {
    text: string
    type: 'single' | 'multitple'
    answer: string
    id: string
    showAns: boolean
    isAnswered: boolean
    upvotes: string[]
    downvotes: string[]
    options: {
      description: string,
      isCorrect: boolean,
      _id: string,
      userAns: boolean
    }[]
  }
 */

const likeHandler = async (questionId, token, body, updateQuestions) => {
  try {
    // v2
    const res = await axios.patch(
      `https://a4medicine.co.uk/api/v1/question/${questionId}/votes`,
      body,
      { headers: { Authorization: `Bearer ${token}` } }
    )

    const downvotes = res.data.data.downvotes
    const upvotes = res.data.data.upvotes
    const id = res.data.data.id

    updateQuestions((lst) => {
      const ques = [...lst]

      const index = ques.findIndex((el) => el.id === id)

      ques[index].upvotes = upvotes
      ques[index].downvotes = downvotes

      return ques
    })
  } catch (err) {
    console.error(err)
  }
}

const storeProgress = async (questionId, token, response, quizId) => {
  const userId = localStorage.getItem('id')
  try {
    // v2
    await axios.post(
      'https://a4medicine.co.uk/api/v1/progress',
      {
        questionId,
        userId,
        response,
        quizId,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  } catch (err) {
    console.error(err)
  }
}

const resetProgress = async (body, token) => {
  try {
    // v2
    await axios.post('https://a4medicine.co.uk/api/v1/progress/reset', body, {
      headers: { Authorization: `Bearer ${token}` },
    })
  } catch (err) {
    console.error(err)
  }
}

const SingleQuiz = () => {
  const location = useLocation()
  const currQuesId = useParams()?.id.split('+').at(-1)
  const [quizDetail, setQuizDetail] = useState(null)
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const [questionIndex, setQuestionIndex] = useState(0)
  const [showAllQuestions, setShowAllQuestions] = useState(false)
  const curUserId = localStorage.getItem('id')
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const subscriptionId = localStorage.getItem('subscriptionId')

  const dualFunction = () => {
    if (!question.showAns)
      setQuestions((lst) => {
        const ques = [...lst]
        ques[questionIndex].showAns = true
        return ques
      })
    storeProgress(question.id, token, question.response, question.quiz.id)
  }

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        // /v2
        const response = await fetch(
          `https://a4medicine.co.uk/api/v1/question/${currQuesId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (!response.ok && response.status === 403)
          throw new Error("You don't have a Active Plan Please Buy One")

        const dat = await response.json()
        if (dat?.sucess === 'fail') throw new Error(dat.message)

        setQuizDetail(dat?.data)

        const quest = []

        const newQues = {
          ...dat.data.question,
          showAns: false,
          isAnswered: false,
          isUserInpCorrect: false,
          response: [],
          options: dat.data.question.options.map((el) => ({
            ...el,
            userAns: false,
          })),
        }

        quest.push(newQues)

        setQuestions(quest)

        setLoading(false)
      } catch (err) {
        setLoading(false)
        setQuizDetail(null)
        setQuestions([])

        if (err.message.includes('Plan')) {
          toast.error(err.message)
          window.scrollTo(0, 0)
          return navigate('/userprofile')
        }
      }
    }

    token && subscriptionId !== 'none' && getData()
  }, [token, subscriptionId])

  useEffect(() => {
    const listener = (e) => {
      if (
        !e.target.closest('#AllQuestions') &&
        !e.target.closest('#showAllQuestions')
      )
        setShowAllQuestions(false)
    }

    document.addEventListener('click', listener)

    return () => {
      document.removeEventListener('click', listener)
    }
  }, [])

  const question = questions.length > 0 ? questions[questionIndex] : null

  useEffect(() => {
    const getProgress = async () => {
      const userId = localStorage.getItem('id')

      setLoading(true)
      try {
        // /v2
        const res = await axios.post(
          'https://a4medicine.co.uk/api/v1/progress/get',
          {
            questionId: question.id,
            userId,
            quizId: question.quiz.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        // If there is no history
        if (!res.data.data) return

        const ques = res.data.data

        // is user is different
        if (ques.userId !== userId) return

        const quesId = ques.questionId
        const response = ques.response

        setQuestions((lst) => {
          const questionsToBeUpdated = [...lst]

          const index = questionsToBeUpdated.findIndex((el) => el.id === quesId)

          questionsToBeUpdated[index].response = response

          // Updating Data
          if (response.length > 0) {
            questionsToBeUpdated[index].options[response[0]].userAns = true // setting userAns to true if there is response
            questionsToBeUpdated[index].isAnswered = true
            questionsToBeUpdated[index].showAns = true
            questionsToBeUpdated[index].options.forEach((opt) => {
              if (opt.userAns && opt.isCorrect)
                questionsToBeUpdated[index].isUserInpCorrect = true
            })
          }

          return questionsToBeUpdated
        })
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    getProgress()
  }, [question])

  let noOfQuesUnAnswered = 0,
    correctAnswer = 0,
    inCorrectAnswer = 0

  questions.forEach((el) => {
    if (!el.isAnswered && el.showAns) noOfQuesUnAnswered += 1

    if (el.isAnswered && el.showAns && el.isUserInpCorrect) correctAnswer += 1
    if (el.isAnswered && el.showAns && !el.isUserInpCorrect)
      inCorrectAnswer += 1
  })

  if (!token || (token && subscriptionId && subscriptionId === 'none'))
    return <Navigate to='/subscription' state={{ from: location }} replace />

  return (
    <>
      {loading && (
        <div className='w-full h-[400px] flex justify-center col-span-full'>
          <Loading visible={true} color={'#0058AB'} />
        </div>
      )}
      {!question && !loading && (
        <div className='mt-40'>
          <h1 className='text-center text-4xl my-5 pt-5 font-semibold'>
            Quiz will be available Soon.
          </h1>
        </div>
      )}
      {!loading && quizDetail && question && (
        <div
          id='Questions'
          className='w-full mt-16 px-6 py-16 flex flex-col items-center relative'>
          <button
            type='button'
            onClick={() =>
              navigate(
                `/quiz/${replaceSpaces(question.quiz.name, '_')}+${
                  question.quiz.id
                }`
              )
            }
            className='px-5 py-1 mt-16 ml-auto bg-green-500 rounded text-green-50 hover:bg-green-600'>
            Go to Quiz
          </button>
          <h2 className='text-3xl mt-4 font-bold text-center md:text-5xl'>
            {quizDetail?.name}
          </h2>

          <div className='w-[80%] m-auto max-[900px]:w-[95%] flex gap-5 flex-row-reverse'>
            <div className='flex-1'>
              <div className='border-b py-4 m-0 mb-10 flex items-center gap-3 flex-col md:flex-row'>
                <div className='flex flex-row gap-3 items-center'>
                  {questionIndex > 0 && (
                    <button
                      type='button'
                      onClick={() => {
                        !question.showAns &&
                          setQuestions((lst) => {
                            const ques = [...lst]

                            // resetting the options
                            ques[questionIndex].options = ques[
                              questionIndex
                            ].options.map((usAn) => ({
                              ...usAn,
                              userAns: false,
                            }))
                            ques[questionIndex].isAnswered = false
                            ques[questionIndex].isUserInpCorrect = false

                            return ques
                          })
                        setQuestionIndex((lst) => lst - 1)
                      }}
                      className='text-2xl text-slate-500 hover:text-slate-700 transition-all hover:scale-110 active:scale-100'>
                      <BiSolidLeftArrow />
                    </button>
                  )}
                  <h5 className='text-xl'>
                    {questionIndex + 1} out of {questions.length} question
                  </h5>
                  {questionIndex < questions.length - 1 && (
                    <button
                      type='button'
                      onClick={() => {
                        !question.showAns &&
                          setQuestions((lst) => {
                            const ques = [...lst]

                            // resetting the options
                            ques[questionIndex].options = ques[
                              questionIndex
                            ].options.map((usAn) => ({
                              ...usAn,
                              userAns: false,
                            }))
                            ques[questionIndex].isAnswered = false
                            ques[questionIndex].isUserInpCorrect = false

                            return ques
                          })
                        setQuestionIndex((lst) => lst + 1)
                      }}
                      className='text-2xl rotate-180 text-slate-500 hover:text-slate-700 transition-all hover:scale-110 active:scale-100'>
                      <BiSolidLeftArrow />
                    </button>
                  )}
                  <BsFillFastForwardFill
                    id='showAllQuestions'
                    onClick={() => setShowAllQuestions(true)}
                    className='text-2xl cursor-pointer text-slate-500 hover:text-slate-700 transition-all hover:scale-110 active:scale-100'
                  />
                </div>
                <div className='flex gap-3 md:ml-auto w-max'>
                  <p className='flex items-center gap-2 capitalize text-sm text-green-500 md:text-xl'>
                    <BsCheckCircleFill className='text-2xl' />
                    {correctAnswer} correct
                  </p>
                  <p className='flex items-center gap-2 text-rose-500 text-sm capitalize md:text-xl'>
                    <MdCancel className=' text-3xl' />
                    {inCorrectAnswer} wrong
                  </p>
                  <p className='flex items-center gap-2 text-sm text-yellow-500 capitalize md:text-xl'>
                    <MdWarning className='text-3xl ' /> {noOfQuesUnAnswered} un
                    attempted
                  </p>
                </div>
              </div>

              <div className='flex flex-col gap-4 md:gap-8'>
                <article className='text-xl font-semibold md:text-2xl'>
                  <h6 className='flex gap-5 items-center mb-4'>
                    Ques {questionIndex + 1}.{' '}
                    <span className='flex items-center gap-2 text-xl'>
                      {question.upvotes.length}
                      {question.upvotes.includes(curUserId) ? (
                        <BiSolidLike className='cursor-pointer text-2xl' />
                      ) : (
                        <BiLike
                          className='cursor-pointer text-xl md:text-2xl'
                          onClick={() => {
                            likeHandler(
                              question.id,
                              token,
                              {
                                upvotes: curUserId,
                              },
                              setQuestions
                            )
                          }}
                        />
                      )}
                    </span>
                    <span className='flex items-center gap-2 text-xl'>
                      {question.downvotes.length}
                      {question.downvotes.includes(curUserId) ? (
                        <BiSolidDislike className='cursor-pointer text-xl md:text-2xl' />
                      ) : (
                        <BiDislike
                          onClick={() => {
                            likeHandler(
                              question.id,
                              token,
                              {
                                downvotes: curUserId,
                              },
                              setQuestions
                            )
                          }}
                          className='cursor-pointer text-xl md:text-2xl'
                        />
                      )}
                    </span>
                  </h6>
                  <div
                    className='overflow-x-auto'
                    dangerouslySetInnerHTML={{ __html: question.text }}
                  />
                </article>

                {/* NOT Answered */}
                {!question.isAnswered && question.showAns && (
                  <p className='text-red-500 flex items-center text-2xl font-semibold gap-3 capitalize'>
                    <ImCancelCircle className='text-3xl' />{' '}
                    <span>unanswered</span>
                  </p>
                )}

                {/* OPTIONS */}
                <div className='flex flex-col gap-3'>
                  {question &&
                    question.options.map((el, i) => (
                      <label
                        key={el._id}
                        htmlFor={el._id}
                        className={`flex gap-3 items-center text-xl w-full py-1 ${
                          el.userAns && 'bg-slate-100'
                        } ${
                          !question.showAns
                            ? 'hover:bg-slate-100 cursor-pointer'
                            : 'cursor-not-allowed'
                        }`}>
                        <input
                          type='radio'
                          disabled={question.showAns}
                          name='correctOption'
                          className='permission_checkbox hidden'
                          id={el._id}
                          value={el._id}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setQuestions((lst) => {
                                const ques = [...lst]

                                // updating options
                                ques[questionIndex].options = ques[
                                  questionIndex
                                ].options.map((usAn) => ({
                                  ...usAn,
                                  userAns: usAn._id === e.target.value,
                                }))

                                // keeping track of if user inp is correct
                                ques[questionIndex].options.forEach((opt) => {
                                  if (opt.userAns && opt.isCorrect)
                                    ques[questionIndex].isUserInpCorrect = true
                                })

                                // updating isAnswered field
                                ques[questionIndex].isAnswered = true

                                // updating response
                                ques[questionIndex].response = [0]

                                return ques
                              })
                            }
                          }}
                        />
                        {!question.showAns && (
                          <>
                            <span className='un_checked_box'>
                              <MdOutlineCheckBoxOutlineBlank className='text-2xl text-slate-600' />
                            </span>
                            <span className='checked_box hidden'>
                              <MdOutlineCheckBox className='text-2xl text-slate-600' />
                            </span>
                          </>
                        )}{' '}
                        {el.description}
                        {question.showAns &&
                          el.userAns &&
                          el.userAns !== el.isCorrect && (
                            <p className='flex gap-2 items-center text-rose-500'>
                              <MdCancel className='' />
                              <span className='capitalize'>wrong answer</span>
                            </p>
                          )}
                        {question.showAns && el.isCorrect && (
                          <p className='flex gap-2 items-center text-green-500'>
                            <BsCheckCircleFill className='' />
                            <span className='capitalize'>correct option</span>
                          </p>
                        )}
                      </label>
                    ))}
                </div>

                {/* ANSWER */}

                {question && question.showAns && (
                  <>
                    <div className='w-[330px] overflow-auto p-4 md:hidden'>
                      <strong className='text-3xl mb-8'>Feedback</strong>
                      <HTMLRenderer htmlString={question.answer} />
                    </div>

                    <div className='md:flex md:flex-col md:gap-2 hidden'>
                      <strong className='text-3xl'>Feedback</strong>

                      <div
                        className=''
                        dangerouslySetInnerHTML={{ __html: question.answer }}
                      />
                    </div>
                  </>
                )}

                <button
                  type='button'
                  onClick={() => {
                    if (!question.showAns) dualFunction()
                    else {
                      navigate(
                        `/quiz/${replaceSpaces(question.quiz.name, '_')}+${
                          question.quiz.id
                        }`
                      )
                      window.scrollTo(0, 0)
                    }
                  }}
                  className='px-8 py-1 text-xl bg-blue-500 text-blue-50 rounded-full self-start hover:bg-blue-600 capitalize'>
                  {!question.showAns ? 'Submit' : 'Go to Quiz'}
                </button>
              </div>
            </div>
          </div>

          {/* All Questions Container */}
          <AnimatePresence mode='wait'>
            {showAllQuestions && (
              <m.aside
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: 'easeIn' }}
                exit={{ opacity: 0 }}
                id='AllQuestions'
                className='absolute right-0 top-50 bg-slate-50 h-[90vh] w-[90%] overflow-auto shadow-md md:h-full md:w-max'>
                <ul className='flex flex-col gap-2 overflow-y-scroll h-full pb-5 max-w-[500px] cus_scrollBar'>
                  <h5 className='mt-12 pb-4 text-center font-semibold text-xl border-b mb-1'>
                    All Questions
                  </h5>
                  {questions.map((el, i) => (
                    <li
                      onClick={() => {
                        setQuestionIndex(i)
                        setShowAllQuestions(false)
                      }}
                      className='flex items-center py-2 border-slate-300 px-3 gap-2 hover:border hover:bg-slate-100 cursor-pointer'>
                      {el.isAnswered ? (
                        <BsCheckCircleFill className='text-2xl text-cyan-500' />
                      ) : (
                        <p
                          className={`w-7 text-base h-7 rounded-full flex items-center justify-center text-cyan-500 border-cyan-500 border`}>
                          {i + 1}
                        </p>
                      )}

                      <p
                        className='text_wrap flex-1'
                        dangerouslySetInnerHTML={{ __html: el.text }}
                      />
                    </li>
                  ))}
                </ul>
              </m.aside>
            )}
          </AnimatePresence>
        </div>
      )}

      {/* RESULT BOX */}
      {/* {showResultSummary && <ResultSummary questions={questions} />} */}
    </>
  )
}

export default SingleQuiz
