import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import ReadMore from './ReadMore'
import axios from 'axios'
import API from '../api'
import { useLocation } from 'react-router-dom'
import PopChart from './PopChart'

const ChartMainDetail = () => {
  const id = useLocation().state?.id
  const [data, setData] = useState('')
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [chartsImages, setChartsImages] = useState([])
  const [activeChartImgIndex, setActiveChartImgIndex] = useState(0)

  const handleClick = () => {
    setIsOpen(true)
  }
  const handleOnClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const { data } = await axios.post(`${API}section/latest`, {
        id: id,
      })

      setData(data.data.latestCharts)

      setChartsImages(data?.data?.latestCharts?.images || [])

      setLoading(false)
    }

    getData()
  }, [id])

  const moveImages = (type) => {
    setActiveChartImgIndex(lst => {
      switch (type) {
        case 'next': return lst === chartsImages.length - 1 ? 0 : lst + 1
        case 'prev': return lst === 0 ? chartsImages.length - 1 : lst - 1
        default: return lst
      }
    })
  }

  const formatText = data?.body?.split('\n').map((item, index) => (
    <React.Fragment key={ index }>
      { item }
      <br />
    </React.Fragment>
  ))

  const Loading = () => {
    return (
      <>
        <div className='cols-md-3'>
          <Skeleton height={ 350 } />
        </div>
        <div className='col-md-3'>
          <Skeleton height={ 350 } />
        </div>
        <div className='col-md-3'>
          <Skeleton height={ 350 } />
        </div>
        <div className='col-md-3'>
          <Skeleton height={ 350 } />
        </div>
      </>
    )
  }

  return (
    <>
      { loading ? (
        <Loading />
      ) : (
        <div className='py-12 mt-40 flex flex-col justify-center items-center text-center'>
          <div className='container'>
            <div className='mt-16 mb-12 section-title'>
              <h2 className='flex text-2xl md:text-5xl justify-center align-center font-bold text-center '>
                <span className=''>{ data.section }</span>
              </h2>
              {/* <hr /> */ }
            </div>

            { chartsImages.length > 0 && <>

              <figure onClick={ handleClick } className='h-[40rem] mx-auto w-max overflow-y-auto relative imgContainer'>
                { chartsImages.map((image, i) => <img onClick={ () => {
                  setActiveChartImgIndex(i)
                  handleClick()
                } } src={ `https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${image}` }
                  // style={ {
                  //   transform: `translateX(-50%) translateY(${(activeChartImgIndex - i) * 100}%)`
                  // } }
                  alt={ `${i}` } className='w-auto h-full transition-all' key={ i } />) }
              </figure>
              {/* { chartsImages.length > 1 && <div className="flex justify-center gap-3 mt-3">
                <button type="button" onClick={ () => moveImages("prev") } className={ `px-5 rounded-md flex items-center justify-center font-poppins text-center py-3 font-semibold bg-[#07358c] text-white transition-all hover:brightness-110` }>Previous</button>
                <button type="button" onClick={ () => moveImages("next") } className={ `px-5 rounded-md flex items-center justify-center font-poppins text-center py-3 font-semibold bg-[#07358c] text-white transition-all hover:brightness-110` }>Next</button>
              </div> } */}
            </>
            }

            { isOpen && (
              <PopChart
                onClose={ handleOnClose }
                visible={ isOpen }
                image={ `https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${chartsImages[activeChartImgIndex]}` }
                name={ data.section }
              />
            ) }


            {/* <img src={} className=""  /> */ }
            { data.isOld === true ? (
              <div>
                { formatText?.length > 0 && (
                  <div className='h-full p-3'>
                    <h5 className='text-5xl font-bold mt-12 mb-8'>
                      { formatText[0] }
                    </h5>
                    <p className='text-2xl font-semibold'>{ formatText[1] }</p>
                    <hr className='bg-black mt-6' />
                    <ReadMore longContent={ formatText.slice(2) } />
                  </div>
                ) }
              </div>
            ) : (
              <div className='mt-8 mx-auto min-w-7xl p-4 rounded-lg'>
                <div className='p-4'>
                  {/* Render the HTML content */ }
                  <div
                    className='overflow-x-auto'
                    dangerouslySetInnerHTML={ { __html: data.body } }
                  />
                </div>
              </div>
            ) }
          </div>
        </div>
      ) }
    </>
  )
}

export default ChartMainDetail
