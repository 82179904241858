import React, { useEffect, useState, useRef } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import Loading from './Loading'
import API from '../api'
import { useNavigate } from 'react-router-dom'
import { ExtendSubscriptionButton } from '../utility'

const SubscriptionPlan = () => {
  const id = localStorage.getItem('id')
  const [subscription, setSubscription] = useState('')
  const [subscriptionItems, setSubscriptionItems] = useState('')
  const [showNotification, setShowNotification] = useState(false)

  const token = localStorage.getItem('token')
  const [isDropdownPlanOpen, setIsDropdownPlanOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const dropdownRef = useRef(null)
  const navigate = useNavigate()
  useEffect(() => {
    // Fetch initial data from the API and populate the form fields
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${API}user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await response.json()
      setSubscription(data.data.subscription)
      setSubscriptionItems(data.data.subscription?.subscription_items[0])
      setLoading(false)
    } catch (error) {
      toast.error(error)
      setLoading(false)
    }
  }

  const trialStart = subscription.current_term_start // UNIX timestamp value
  const trialEnd = subscription.current_term_end // UNIX timestamp value
  const changeDate = subscription.next_billing_at // UNIX timestamp value
  const cancelAt = subscription.cancelled_at
  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000)
    return date.toDateString() // Convert date to a human-readable format
  }

  const StartDate = convertTimestampToDate(trialStart)
  const EndDate = convertTimestampToDate(trialEnd)
  const ChangeDate = convertTimestampToDate(changeDate)
  const CancelAt = convertTimestampToDate(cancelAt)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownPlanOpen(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const [initialform, setInitialForm] = useState({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
  })
  useEffect(() => {
    // Fetch initial data from the API and populate the form fields
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await fetch(`${API}user/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        const data = await response.json()
        setInitialForm(data.data.user)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error)
      }
    }
    fetchData()
  }, [])

  const handleConfirm = async () => {
    // Logic for handling confirm action
    fetchSubscription()

    setShowNotification(false)
  }

  const handleCancel = () => {
    // Logic for handling cancel action
    setShowNotification(true)
  }

  const fetchSubscription = async () => {
    try {
      const response = await fetch(`${API}subscription/cancel`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const cancelDetail = await response.json()

      if (cancelDetail.status === 'success') {
        toast.success('Your Plan is processing in Cancel!')
        navigate(0)
      } else {
        toast.error(cancelDetail.message)
      }
    } catch (error) {}
  }

  const subscriptionId = localStorage.getItem('subscriptionId')

  return (
    <div className='h-full flex flex-col justify-center bg-[#E1E9FB] items-center pt-48 mt-8'>
      <Toaster />
      <div className='flex flex-col md:flex-row items-center justify-center'>
        {subscriptionId !== 'none' && (
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-5 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#9d9b9a]'>
            <Link to='/subscriptionPlan'>Subscription</Link>
          </button>
        )}
        <Link to='/userprofile'>
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-5 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#F17732]'>
            Edit Profile
          </button>
        </Link>
        <Link to='/customersupport'>
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-2 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#F17732]'>
            Customer Support
          </button>
        </Link>
        <Link to='/planextension'>
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-4 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#F17732]'>
            Extend Trial Pass
          </button>
        </Link>
        <Link to='/feedback'>
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-4 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#F17732]'>
            Feedback
          </button>
        </Link>
      </div>

      <div className='w-[90%] bg-white shadow-lg p-8 my-20'>
        <h1 className='text-2xl font-semibold text-center'>
          Subscription Plan
        </h1>

        {loading ? (
          <>
            <Loading color='#0058AB' />
          </>
        ) : (
          <div className='md:w-[70%] problem-table text-center mt-8 mx-auto'>
            <table className='entire-table w-full text-gray-800 divide-gray-200 text-center border-collapse border border-slate-400 border-spacing-y-2.5'>
              <tbody className='table-body text-base md:text-lg border-collapse border border-slate-400'>
                <tr className='bg-color table-row border-collapse border border-slate-400 text-start font-extrabold text-5xl'>
                  <td className='text-xl text-gray-800 p-2'>Info</td>
                  <td className='text-xl text-bold text-black p-2'>
                    User Details
                  </td>
                </tr>

                <tr className='bg-color table-row border-collapse border border-slate-400 text-start'>
                  <td className='text-xl text-gray-800 p-2'>Subscription Id</td>
                  <td className='text-xl text-bold text-black p-2'>
                    {subscription.id}
                  </td>
                </tr>
                <tr class='bg-color table-row border-collapse border border-slate-400 text-start'>
                  <td className='text-xl text-gray-800 p-2'>
                    Subscription Plan
                  </td>
                  <td className='text-xl text-bold text-black p-2'>
                    {subscriptionItems.item_price_id}
                  </td>
                </tr>
                <tr class='bg-color table-row border-collapse border border-slate-400 text-start'>
                  <td className='text-xl text-gray-800 p-2'>Status</td>
                  <td className='text-xl text-bold text-black p-2'>
                    {subscription.status}
                  </td>
                </tr>
                <tr class='bg-color table-row border-collapse border border-slate-400 text-start'>
                  <td className='text-xl text-gray-800 p-2'>
                    Subscription Start Date
                  </td>
                  <td className='text-xl text-bold text-black p-2'>
                    {StartDate}
                  </td>
                </tr>
                <tr class='bg-color table-row border-collapse border border-slate-400 text-start'>
                  <td className='text-xl text-gray-800 p-2'>
                    Subscription End Date
                  </td>
                  <td className='text-xl text-bold text-black p-2'>
                    {EndDate}
                  </td>
                </tr>

                {CancelAt !== 'Invalid Date' ? (
                  <tr class='bg-color table-row border-collapse border border-slate-400 text-start'>
                    <td className='text-xl text-gray-800 p-2'>Cancel At</td>
                    <td className='text-xl text-bold text-black p-2'>
                      {CancelAt}
                    </td>
                  </tr>
                ) : (
                  <tr class='bg-color table-row border-collapse border border-slate-400 text-start'>
                    <td className='text-xl text-gray-800 p-2'>
                      Next Billing Date
                    </td>
                    <td className='text-xl text-bold text-black p-2'>
                      {ChangeDate}
                    </td>
                  </tr>
                )}

                <tr class='bg-color table-row border-collapse border border-slate-400 text-start'>
                  <td className='text-xl text-gray-800 p-2'>
                    Subscription Price
                  </td>
                  <td className='text-xl text-bold text-black p-2'>
                    {subscription.currency_code}{' '}
                    {subscriptionItems.unit_price / 100} per month
                  </td>
                </tr>
              </tbody>
            </table>
            {subscription.next_billing_at && (
              <button
                className='px-4 mx-1 py-2 mt-4 bg-red-500 text-white font-extrabold rounded'
                onClick={handleCancel}>
                Cancel Subscription
              </button>
            )}
            {showNotification && (
              <div className='bg-white border lg:ml-[350px] ml-0  top-0 fixed z-[1000] rounded-lg p-4 mt-4'>
                <p className='text-gray-800 text-lg mb-2'>
                  Are you sure you want to cancel your plan?
                </p>
                <div className='flex justify-end'>
                  <button
                    className='bg-green-500 text-white px-4 py-2 rounded mr-2'
                    onClick={handleConfirm}>
                    Confirm
                  </button>
                  <button
                    className='bg-red-500 text-white px-4 py-2 rounded'
                    onClick={() => setShowNotification(false)}>
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default SubscriptionPlan
