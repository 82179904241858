import React from 'react'

const CaseWrapper = ({ children, heading }) => {
  return (
    <div className='flex flex-col items-center gap-4 relative'>
      <section className='bg-[#213555] pt-40 pb-30 text-white'>
        <h1 className='text-center text-6xl font-bold'>{heading}</h1>
      </section>

      {children}
    </div>
  )
}

export default CaseWrapper
