import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import toast from 'react-hot-toast'
import API from '../api'
import { BsCardImage } from 'react-icons/bs'
import { useForm } from 'react-hook-form'
import {
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineCheckBox,
} from 'react-icons/md'
import { ErrorMessage } from '@hookform/error-message'

import { displayStars } from '../utility'

export const ConnectToAPI = async ({ URL, body, method }) => {
  const token = localStorage.getItem('token')
  const url = URL.includes('http')
    ? URL
    : `https://a4medicine.co.uk/api/v1/${URL}`

  const OPTIONS =
    method === 'GET'
      ? {
          method,
          headers: { Authorization: `Bearer ${token}` },
        }
      : {
          method,
          body,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }

  const reponse = await fetch(url, OPTIONS)

  const data = await reponse.json()

  // Logging out if not authenicated
  if (!data.authenticated && !data.status) {
    throw new Error('')
  }

  // handling err
  if (data.status === 'fail') {
    if (data.message.toLowerCase().includes('cast'))
      throw new Error('Something went wrong!\n Try again later')
    else throw new Error(data.message)
  }

  return data
}

export const getS3FileUploadData = async ({ folderName, format }) => {
  try {
    const data = await ConnectToAPI({
      method: 'POST',
      body: JSON.stringify({ folderName, format }),
      URL: 'image',
    })

    return { signedUrl: data.data.signedUrl, key: data.data.key }
  } catch (err) {
    console.error(err)
  }
}

export const FeedbackScreen = () => {
  const [sending, setSending] = useState(false)
  const navigate = useNavigate()
  const feedback = localStorage.getItem('feedback') || ''
  const rating = localStorage.getItem('rating') || 0
  const isShow = localStorage.getItem('isShow') || ''
  const isContact = localStorage.getItem('isContact') || ''
  const [image, setImage] = useState(localStorage.getItem('userImage') || '')

  const token = localStorage.getItem('token')

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: { feedback, rating, isShow, isContact },
  })
  const location = useLocation()

  const storeRating = (e) => {
    const id = e.target.closest('svg').id

    setValue('rating', +id)
  }

  const feedbackHandler = async (data) => {
    setSending(true)
    const userId = localStorage.getItem('id')
    const token = localStorage.getItem('token')
    try {
      const res = await fetch(
        `https://a4medicine.co.uk/api/v1/feedback/${userId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            feedback: data.feedback,
            isShow: data.isShow === 'yes',
            isContact: data.isContact === 'yes',
            rating: data.rating,
            image: image || undefined,
          }),
        }
      )
      await res.json()

      localStorage.setItem('feedback', data.feedback)
      localStorage.setItem('isShow', data.isShow)
      localStorage.setItem('isContact', data.isContact)
      localStorage.setItem('rating', data.rating)
      localStorage.getItem('userImage', image)
      toast.success('Feedback updated successfully.')
      navigate('/')
    } catch (err) {
      toast.error(err.message)
    } finally {
      setSending(false)
    }
  }

  const imageUploadHandler = async (e) => {
    const file = e.target.files[0]

    if (!file) return

    if (!file.type.includes('image'))
      return setError('image', { message: 'Please select image only' })

    const fileFormat = file.type.split('/').at(-1)
    toast.success('Uploading Image...')

    try {
      const data = await getS3FileUploadData({
        folderName: 'charts',
        format: fileFormat,
      })

      // Uploading Image to signedURL
      const res = await fetch(data?.signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      })

      if (res.ok) {
        // setNotTouchable(false)
        toast.success('Image Uploaded successfully')

        // If everything goes right then set image value to key
        setImage(data?.key)
      }
    } catch (err) {
      console.error(err)
    }
  }

  if (!token) return <Navigate to='/login' state={{ from: location }} replace />
  const subscriptionId = localStorage.getItem('subscriptionId')
  return (
    <>
      <div className='h-full flex flex-col justify-center bg-[#E1E9FB] items-center pt-48 mt-8'>
      <div className='flex flex-col md:flex-row items-center justify-center'>
        {subscriptionId !== 'none' && (
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-5 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#9d9b9a]'>
            <Link to='/subscriptionPlan'>Subscription</Link>
          </button>
        )}
        <Link to='/userprofile'>
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-5 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#F17732]'>
            Edit Profile
          </button>
        </Link>
        <Link to='/customersupport'>
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-2 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#F17732]'>
            Customer Support
          </button>
        </Link>
        <Link to='/planextension'>
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-4 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#F17732]'>
            Extend Trial Pass
          </button>
        </Link>
        <Link to='/feedback'>
          <button className='bg-[--main-color] text-base flex items-center justify-center text-white text-center font-[Poppins] px-4 cursor-pointer py-2 rounded md:ml-4 mt-4 md:mt-0 hover:bg-[#F17732]'>
            Feedback
          </button>
        </Link>
      </div>
        <form
          onSubmit={handleSubmit(feedbackHandler)}
          className='bg-white shadow-md rounded flex flex-col  gap-4 w-[90%] md:w-[50%] justify-center p-8 my-20'>
          <h2 className='font-bold text-3xl'>Feedback Form</h2>

          <div className='flex gap-8 items-end'>
            <label htmlFor='image' className='flex flex-col gap-3'>
              <span className='text-lg font-bold flex gap-2 items-center'>
                Upload Image <BsCardImage />
              </span>
              <input
                type='file'
                id='image'
                className='hidden'
                onChange={imageUploadHandler}
              />
            </label>

            {image && (
              <img
                src={`https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${image}`}
                alt='userPic'
                className='w-16 aspect-square object-cover object-center rounded-full'
              />
            )}
          </div>

          <label className='flex flex-col gap-3' htmlFor='rating'>
            <span className='text-lg font-bold'>
              How would you rate your overall satisfaction with A4 Medicine
              resources?
            </span>
            <input
              type='text'
              id='rating'
              className='hidden'
              {...register('rating', { required: 'Rating is required' })}
            />

            <span className='flex items-center gap-1 text-3xl'>
              {displayStars(watch('rating'), storeRating)}
            </span>

            <ErrorMessage
              errors={errors}
              as={'span'}
              name='rating'
              className='text-sm text-red-500 font-bold'
            />
          </label>

          <label htmlFor='feedback' className='flex flex-col gap-3'>
            <span className='text-lg font-bold'>
              What improvements or additional content would you like to see on
              A4 Medicine?
            </span>
            <textarea
              rows='5'
              {...register('feedback', { required: 'Feedback is required' })}
              placeholder='User Feedback'
              className='outline-none border p-2 resize-none border-gray-200 w-full rounded-md bg-gray-50'></textarea>
            <ErrorMessage
              errors={errors}
              as={'span'}
              name='feedback'
              className='text-sm text-red-500 font-bold'
            />
          </label>

          <label htmlFor='isShow' className='flex flex-col gap-3'>
            <span className='text-lg font-bold'>
              If you are pleased with our resources, may we use your comments as
              a testimonial?
            </span>
          </label>
          <div className='flex items-center gap-10'>
            <label
              htmlFor='yes'
              className='flex items-center cursor-pointer gap-1 w-max'>
              <input
                type='radio'
                className='permission_checkbox hidden'
                id='yes'
                value={'yes'}
                {...register('isShow', { required: 'Field is required' })}
              />
              <span className='un_checked_box'>
                <MdOutlineCheckBoxOutlineBlank className='text-2xl text-cyan-800' />
              </span>
              <span className='checked_box hidden'>
                <MdOutlineCheckBox className='text-2xl text-cyan-800' />
              </span>

              <p className='capitalize text-md'>Yes</p>
            </label>

            <label
              htmlFor='no'
              className='flex items-center cursor-pointer gap-1 w-max'>
              <input
                type='radio'
                className='permission_checkbox hidden'
                id='no'
                value={'no'}
                {...register('isShow', { required: 'Field is required' })}
              />
              <span className='un_checked_box'>
                <MdOutlineCheckBoxOutlineBlank className='text-2xl text-cyan-800' />
              </span>
              <span className='checked_box hidden'>
                <MdOutlineCheckBox className='text-2xl text-cyan-800' />
              </span>

              <p className='capitalize text-md'>No</p>
            </label>
          </div>
          <ErrorMessage
            errors={errors}
            as={'span'}
            name='isShow'
            className='text-sm text-red-500 font-bold'
          />

          <label htmlFor='isContact' className='flex flex-col gap-3'>
            <span className='text-lg font-bold'>
              May we contact you to discuss your feedback in more detail?
            </span>
          </label>

          <div className='flex items-center gap-10'>
            <label
              htmlFor='isContactYes'
              className='flex items-center cursor-pointer gap-1 w-max'>
              <input
                type='radio'
                className='permission_checkbox hidden'
                id='isContactYes'
                value={'yes'}
                {...register('isContact', { required: 'Field is required' })}
              />
              <span className='un_checked_box'>
                <MdOutlineCheckBoxOutlineBlank className='text-2xl text-cyan-800' />
              </span>
              <span className='checked_box hidden'>
                <MdOutlineCheckBox className='text-2xl text-cyan-800' />
              </span>

              <p className='capitalize text-md'>Yes</p>
            </label>

            <label
              htmlFor='isContactNo'
              className='flex items-center cursor-pointer gap-1 w-max'>
              <input
                type='radio'
                className='permission_checkbox hidden'
                id='isContactNo'
                value={'no'}
                {...register('isContact', { required: 'Field is required' })}
              />
              <span className='un_checked_box'>
                <MdOutlineCheckBoxOutlineBlank className='text-2xl text-cyan-800' />
              </span>
              <span className='checked_box hidden'>
                <MdOutlineCheckBox className='text-2xl text-cyan-800' />
              </span>

              <p className='capitalize text-md'>No</p>
            </label>
          </div>
          <ErrorMessage
            errors={errors}
            as={'span'}
            name='isContact'
            className='text-sm text-red-500 font-bold'
          />

          <button
            disabled={sending}
            type='submit'
            className='bg-[--main-color] self-start flex items-center justify-center text-white text-center font-[Poppins] px-4 cursor-pointer py-1 rounded hover:bg-[#F17732] disabled:bg-slate-400 disabled:cursor-not-allowed'>
            {sending ? <span className='feedbackLoader' /> : 'Submit'}
          </button>
        </form>
      </div>
    </>
  )
}
