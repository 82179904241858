import React, { useState, useEffect } from 'react'
import BookView from './../components/BookView'
import Author from '../components/Author'
import VisualBook from '../components/VisualBook'
import BookSell from '../components/BookSell'
import BookUser from '../components/BookUser'
import BookIndex from '../components/BookIndex'
import AboutBook from '../components/AboutBook'
import toast, { Toaster } from 'react-hot-toast'
import BookComponent from '../components/BookComponent'
import API from '../api'
import Loading from '../components/Loading'
import { useParams } from 'react-router-dom'

const VisualGuideBook = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState('')

  const id = useParams()?.id.split('+').at(-1)

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        const response = await fetch(`${API}book/${id}`)

        const dat = await response.json()

        if (dat.status === 'success') {
          setData(dat.data)
          setLoading(false)
        } else {
          toast.error(dat.message)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    }

    getData()
  }, [id])

  return (
    <div className='mt-32'>
      <Toaster />
      {loading ? (
        <div className='h-screen  flex items-center justify-center'>
          <Loading color='#0058AB' />
        </div>
      ) : (
        <div>
          <BookComponent
            image={data.image}
            title=''
            subtitle={data.name}
            image1={data.image}
          />
          <AboutBook image={data.image} desc={data ? data.description : ''} />
          <BookView
            price={data.price}
            link={data.paymentLink}
            video={data.video}
            id={data.id}
            name={data.name}
            image={data.image}
            discount={data.discount}
            planOffer={data.planOffer}
          />
          <BookIndex
            BookIndex1={data.indexImage ? data.indexImage[0] : ''}
            BookIndex2={data.indexImage ? data.indexImage[1] : ''}
            // BookIndex2={data.indexImage[1]}
          />
          <VisualBook
            title={data.title}
            link={data.paymentLink}
            price={data.price}
            image={data.image}
            name={data.name}
            id={data.id}
            discount={data.discount}
            planOffer={data.planOffer}
            VisualTopic={data.points ? data.points : []}
          />
          <BookSell
            link={data.paymentLink}
            price={data.price}
            image={data.image}
            id={data.id}
            name={data.name}
          />
          <BookUser />
          <Author />
        </div>
      )}
    </div>
  )
}

export default VisualGuideBook
