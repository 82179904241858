import React from 'react'
import { useFetch } from '../hooks/useFetch'
import { useLocation, useParams, Navigate } from 'react-router-dom'
import Loading from '../components/Loading'
import CaseWrapper from '../wrappers/CaseWrapper'

const ExplanationsDetailsScreen = () => {
  window.scrollTo(0, 0)
  const id = useParams()?.id?.split('+')
  const { data, loading } = useFetch(`sca-explanation/${id[1]}`)
  const token = localStorage.getItem('token')
  const subscriptionId = localStorage.getItem('subscriptionId')
  const location = useLocation()

  if (!token || (token && subscriptionId && subscriptionId === 'none'))
    return <Navigate to='/subscription' state={{ from: location }} replace />

  const explanation = data?.ScaExplanation || null

  return (
    <CaseWrapper heading={id[0].replaceAll('`', ' ')}>
      {loading && <Loading color={'#213555'} visible={true} />}
      {!loading && explanation && (
        <section className='max-[1160px]:px-10  max-sm:px-5'>
          <div className='w-[1150px] mx-auto max-[1160px]:w-full flex flex-col gap-10'>
            <div
              className='align w-full aspect-video'
              dangerouslySetInnerHTML={{ __html: explanation.videoUrl }}
            />

            <div
              dangerouslySetInnerHTML={{ __html: explanation.description }}
            />
          </div>
        </section>
      )}
      {!loading && !explanation && (
        <p className='col-span-3 text-center text-4xl font-bold'>
          OOPS!! No Data Found
        </p>
      )}
    </CaseWrapper>
  )
}

export default ExplanationsDetailsScreen
