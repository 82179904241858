import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import scrollToTop from './scrollToTop'

const Book = () => {
  const [books, setBooks] = useState([])

  useEffect(() => {
    // Fetch book data from the API
    fetch('https://a4medicine.co.uk/api/v1/book')
      .then((response) => response.json())
      .then((data) => {
        // Update image URLs with S3 prefix
        const booksWithS3Prefix = data.data.books.map((book) => ({
          ...book,
          image: `https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${book.image}`,
        }))
        setBooks(booksWithS3Prefix)
      })
      .catch((error) => console.error('Error fetching book data:', error))
  }, [])

  return (
    <div className='bg-[--main-color]  my-12'>
      <div className='flex flex-wrap justify-evenly text-white p-8'>
        {books.map((book) => (
          <div
            key={book.id}
            className='text-center flex flex-col mt-6 items-center justify-between'>
            <img src={book.image} alt={book.title} className='h-96' />
            <h2 className='text-2xl font-bold p-3'>{book.title}</h2>
            <h5 className='text-xl font-semibold'>Just For £{book.price}</h5>
            <button className='border-2 bg-white font-semibold px-8 mt-4 text-xl h-12 text-black'>
              <Link to={`/book/${book.id}`}>Buy Now</Link>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Book
