/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { CartContext } from "../context/CartContext";
import { FaMinus, FaPlus } from "react-icons/fa";
import { BiSolidOffer } from "react-icons/bi";
import { MdDiscount } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import toast from "react-hot-toast";

export const CartScreen = () => {
  const ctx = useContext(CartContext);
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("");
  const [applyingCoupon, setApplyingCoupon] = useState(false);
  const [validCoupon, setValidCoupon] = useState(false);
  const discount =
    ctx.cart.length > 0
      ? ctx.cart.reduce((prev, cur) => cur.discountAmount + prev, 0)
      : 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateCoupon = async () => {
    const token = localStorage.getItem("token");
    const OPTIONS = {
      method: "POST",
      body: JSON.stringify({ code: couponCode }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    try {
      setValidCoupon(false);
      setApplyingCoupon(true);

      const reponse = await fetch(
        "https://a4medicine.co.uk/api/v1/coupon/validate",
        OPTIONS
      );

      const data = await reponse.json();

      // handling err
      if (data.status === "fail") {
        if (data.message.toLowerCase().includes("cast"))
          throw new Error("Something went wrong!\n Try again later");
        else throw new Error(data.message);
      }

      setApplyingCoupon(false);
      const bookId = data.data.book;
      const couponDetails = data?.data;
      const cart = [...ctx.cart];

      // Checking Expiry Date
      if (new Date(couponDetails.expireDate) < new Date())
        return toast.error("Coupon is expired");

      // Checking Limit
      if (couponDetails.limit <= 0) return toast.error("Coupon Limit Exceed");

      // Checking Coupon is applicable or not
      if (!cart.some((el) => el.id === bookId)) {
        setCouponCode("");
        return toast.error(
          "This coupon is not applicable on any items present in cart"
        );
      }

      setValidCoupon(true);
      const index = cart.findIndex((el) => el.id === bookId);

      if (!couponDetails.isPercentage) {
        ctx.reduceDiscount({ amount: couponDetails.amount, index });

        return toast.success("successfully applied");
      }

      const disCountBookPrevTotal = cart[index].qty * cart[index].price;

      const discountAmount =
        disCountBookPrevTotal * (couponDetails.amount / 100);

      ctx.reduceDiscount({ amount: discountAmount, index });

      toast.success(
        couponDetails.amount +
          `${couponDetails.isPercentage ? " %" : "£"}` +
          " coupon applied successfully"
      );
    } catch (err) {
      setCouponCode("");
      console.error(err);
      setApplyingCoupon(false);
      toast.error(err.message);
      setValidCoupon(false);
    }
  };

  return (
    <>
      <div className="mt-[160px]">
        {ctx.cart.length === 0 && (
          <h1 className="text-center font-semibold text-5xl py-20 ">
            Your Cart is Empty
          </h1>
        )}

        {ctx.cart.length > 0 && (
          <section className="md:py-5 flex flex-col md:px-28 sm:py-4 ">
            <h1 className="text-5xl font-semibold text-center">
              Your Cart ({ctx.cart.length})
            </h1>

            <div className="sm:mt-10 m-2">
              {ctx.plan === "super" && (
                <p className="sm:w-full py-2 mb-4 px-3 sm:px-6 bg-[#FFD700] text-black rounded flex flex-col sm:flex-row items-center gap-2">
                  <BiSolidOffer className="text-xl" />
                  <span className="text-center sm:text-left">
                    12 months full site access subscription with every book
                    purchase
                  </span>
                </p>
              )}
              <table className="w-full">
                <thead>
                  <tr className="border-b border-slate-300">
                    <th className="py-2 text-lg text-left">Item</th>
                    <th className="py-2 text-lg">Price</th>
                    <th className="py-2 text-lg">Quantity</th>
                    <th className="py-2 text-lg">Total</th>
                  </tr>
                </thead>

                <tbody>
                  {ctx.cart.map((el) => (
                    <CartSection
                      {...el}
                      setCouponCode={setCouponCode}
                      setValidCoupon={setValidCoupon}
                      showPlanOffer={ctx.plan !== "super"}
                      offerApplied={
                        ctx.cart.length === 1 && ctx.plan !== "super"
                      }
                    />
                  ))}
                </tbody>
              </table>
            </div>

            <div className="m-2 self-end sm:py-10 sm:mt-8 flex flex-col gap-2 sm:w-full sm:mx-auto sm:max-w-screen-md">
              <div className="flex justify-between gap-20 border-b text-lg">
                <h4 className="font-semibold">Subtotal:</h4>
                <p>
                  £{" "}
                  {ctx.cart.reduce((prev, cur) => cur.totalPrice + prev, 0) +
                    ctx.planPrice}
                </p>
              </div>

              <div className="flex justify-between gap-20 border-b text-lg">
                <h4 className="font-semibold">Taxes:</h4>
                <p>£ {ctx.taxes}</p>
              </div>

              {discount > 0 && (
                <div className="flex justify-between gap-20 border-b text-lg">
                  <h4 className="font-semibold">Discounts:</h4>
                  <p>£ {discount}</p>
                </div>
              )}

              <div className="flex justify-between gap-20 border-b text-lg">
                <h4 className="font-semibold">Apply Coupon:</h4>
                <div className="flex gap-2 items-end">
                  <input
                    type="text"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    className="border-0 border-b mb-2 placeholder:capitalize outline-none border-slate-300 focus:bg-slate-100"
                    placeholder="coupon"
                  />
                  <button
                    type="button"
                    disabled={applyingCoupon}
                    onClick={() => {
                      validateCoupon();
                    }}
                    className="text-sky-800 hover:underline"
                  >
                    {applyingCoupon ? "applying..." : "Apply"}
                  </button>
                </div>
              </div>

              {validCoupon && (
                <div className="flex justify-between gap-20 border-b text-lg">
                  <h4 className="font-semibold">Discount:</h4>
                  <p>£ {ctx.discountAmount}</p>
                </div>
              )}

              <div className="flex justify-between gap-20 text-lg">
                <h4 className="font-semibold">Grand total:</h4>
                <p className="text-4xl">
                  <span>£ {ctx.grandTotal + ctx.planPrice - discount}</span>
                </p>
              </div>

              <button
                disabled={applyingCoupon}
                onClick={() => {
                  setValidCoupon(false);
                  navigate("/checkout");
                }}
                type="button"
                className="py-1 bg-sky-900 text-white mt-5 disabled:bg-slate-100 disabled:text-slate-500"
              >
                Proceed
              </button>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

const CartSection = (props) => {
  const ctx = useContext(CartContext);
  const [showOffer, setShowOffer] = useState(true);
  const index = props?.planOffer?.findIndex((el) => el.planName === ctx.plan);
  const [offerIndex, setOfferIndex] = useState(index);

  const planOffers = props.planOffer
    ? props.planOffer.filter((el) => el.magnitude > 0)
    : [];

  const offerPrice =
    planOffers.length > 0 && offerIndex > -1
      ? planOffers[offerIndex].magnitude
      : "";

  useEffect(() => {
    if (!showOffer) {
      setOfferIndex(-1);
      ctx.addPlan("");
      ctx.changePlanPrice(0);
    }
  }, [showOffer]);

  useEffect(() => {
    !props.showPlanOffer && setOfferIndex(-1);
  }, [props.showPlanOffer]);

  return (
    <>
      <tr
        className={`${
          planOffers.length > 0 && props.showPlanOffer
            ? ""
            : "border-slate-300 border-b"
        }`}
      >
        <td className="py-5 text-lg w-[40%]">
          <div className="flex items-center gap-3">
            <img
              src={
                "https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/" +
                props.image
              }
              alt="item pic"
              class="h-[6rem] w-auto hidden sm:visible md:block"
            />

            <p className="font-semibold">
              {props.name}{" "}
              {props.discount && props.discount.length > 0 && (
                <div className="discount_overlay relative cursor-pointer w-max">
                  <MdDiscount className="animate-bounce" />

                  <ul className="hidden flex-col w-max font-normal bg-white rounded shadow-md absolute top-full left-0 cursor-default">
                    {props.discount.map((el) => (
                      <li key={el._id} className="p-3 border-b">
                        <strong>£ {el.magnitude}</strong> discount on purchase
                        of <strong>{el.quantity} books</strong>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </p>
          </div>
        </td>
        <td className="py-2 text-lg text-center">£ {props.price}</td>
        <td className="py-2 text-lg text-center">
          <div className="rounded-md border-2 flex border-black w-max m-auto h-max">
            <button
              type="button"
              onClick={() => {
                ctx.removeFromCart(props.id);
                props.setCouponCode("");
                props.setValidCoupon(false);
                ctx.removeDiscount();
              }}
              className="px-3 text-base hover:bg-slate-100"
            >
              <FaMinus />
            </button>
            <p className="px-5 border-r-2 border-l-2 border-black self-stretch flex justify-center items-center">
              {props.qty}
            </p>
            <button
              type="button"
              onClick={() => {
                ctx.addToCart({ ...props });
                props.setCouponCode("");
                props.setValidCoupon(false);
                ctx.removeDiscount();
              }}
              className="px-3 text-base hover:bg-slate-100"
            >
              <FaPlus />
            </button>
          </div>
        </td>
        <td className="py-2 text-lg text-center font-semibold">
          {props.discountAmount > 0 ? (
            <>
              <del>£ {props.qty * props.price}</del>{" "}
              <span>£ {props.qty * props.price - props.discountAmount} </span>{" "}
            </>
          ) : (
            <span>£ {props.qty * props.price}</span>
          )}
        </td>
      </tr>

      {/* { planOffers.length > 0 && props.showPlanOffer && (
        <tr className="border-b border-gray-300">
          <td>
            <div className="flex flex-col gap-2 mb-3">
              <p className="font-semibold">
                We're excited to offer our valued subscribers a fantastic
                opportunity to enhance their learning experience. For a limited
                time, when you add our book to your subscription, you'll unlock
                exclusive discounts on our subscription plans:{ " " }
                <span
                  className="underline cursor-pointer hover:text-indigo-500 w-max"
                  onClick={ () => {
                    setShowOffer((lst) => !lst);
                  } }
                >
                  { showOffer ? "Remove Offer" : "Apply Offer" }
                </span>
              </p>

              { showOffer &&
                planOffers.map((el, i) => {
                  return (
                    <>
                      <label
                        htmlFor={ `isShow+${el._id}` }
                        key={ el._id }
                        className="flex items-center cursor-pointer gap-1 w-max"
                      >
                        <input
                          type="radio"
                          name="memberShip"
                          className="permission_checkbox hidden"
                          defaultChecked={ ctx.plan === el.planName }
                          id={ `isShow+${el._id}` }
                          onChange={ (e) => {
                            if (e.target.checked) {
                              ctx.addPlan(el.planName);
                              setOfferIndex(i);
                              ctx.changePlanPrice(el.magnitude);
                            }
                          } }
                        />
                        <span className="un_checked_box">
                          <MdOutlineCheckBoxOutlineBlank className="text-2xl text-cyan-800" />
                        </span>
                        <span className="checked_box hidden">
                          <MdOutlineCheckBox className="text-2xl text-cyan-800" />
                        </span>

                        <p className="capitalize text-md font-semibold">
                          { el.planName }
                        </p>
                      </label>
                      <p>{ el.description }</p>
                    </>
                  );
                }) }
            </div>
          </td>
          <td className="py-2 text-lg text-center">
            { offerIndex > -1 && "£" + offerPrice }
          </td>

          <td className="py-2 text-lg text-center">
            { offerIndex > -1 && "N/A" }
          </td>
          <td className="py-2 text-lg text-center font-semibold">
            { offerIndex > -1 && "£" + offerPrice }
          </td>
        </tr>
      ) } */}
    </>
  );
};
