import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import 'react-loading-skeleton/dist/skeleton.css'
import scrollToTop from './scrollToTop'
import Loading from './Loading'
import toast, { Toaster } from 'react-hot-toast'
import API from '../api'
import { replaceSpaces } from '../utility'

const ChartSubTopicIndex = () => {
  const id = useParams()?.id.split('+').at(-1)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const token = localStorage.getItem('token')

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        const response = await fetch(`${API}chart/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const dat = await response.json()
        const sortedData = [...dat?.data?.section].sort((a, b) =>
          a.topic.localeCompare(b.topic)
        )

        if (dat.status === 'success') {
          setLoading(false)
          setData(sortedData)
          setTitle(dat?.data?.topic)
        } else {
          toast.error(dat.message)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    }
    id && getData()
  }, [id, token])

  return (
    <div className='py-24 pb-10 md:pt-0 pt-48 bg-[--main-color] h-full'>
      <Toaster />
      <div className='container mx-auto'>
        <div className='my-8'>
          <h3 className='text-4xl font-bold text-center text-white mb-6 mt-20'>
            {title}
          </h3>
        </div>
        <div className='flex'>
          {loading ? (
            <Loading color='#ffffff' />
          ) : (
            <ul className='flex flex-col mt-5 bg-white w-full'>
              {data
                .slice() // Create a shallow copy of the data array
                .sort((a, b) => a.section.localeCompare(b.section)) // Sort the copy alphabetically by the 'topic' property
                .map((currElem, i) => (
                  <li
                    key={currElem.id}
                    className='flex items-center gap-4 p-4 border-b border-slate-200 text-xl'>
                    <strong>{i + 1}.</strong>
                    <Link
                      to={`/chart/details/${replaceSpaces(
                        currElem.section,
                        '_'
                      )}+${currElem.id}`}
                      onClick={scrollToTop}
                      className='hover:text-sky-800 hover:border-b border-sky-500 hover:font-bold'>
                      {currElem.section}
                    </Link>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChartSubTopicIndex
