import React from 'react'
import Loading from '../components/Loading'
import CaseWrapper from '../wrappers/CaseWrapper'
import { useFetch } from '../hooks/useFetch'
import { useParams, useLocation, Navigate } from 'react-router-dom'

const GuideDetailsScreen = () => {
  window.scrollTo(0, 0)
  const id = useParams()?.id?.split('+')
  const { data, loading } = useFetch(`sca-consultation-guide/${id[1]}`)
  const token = localStorage.getItem('token')
  const subscriptionId = localStorage.getItem('subscriptionId')
  const location = useLocation()

  if (!token || (token && subscriptionId && subscriptionId === 'none'))
    return <Navigate to='/subscription' state={{ from: location }} replace />

  const guide = data?.ScaConsultation || null

  return (
    <CaseWrapper heading={id[0].replaceAll('`', ' ')}>
      {loading && <Loading color={'#213555'} visible={true} />}
      {!loading && guide && (
        <section className='max-[1160px]:px-10  max-sm:px-5'>
          <div className='w-[1150px] mx-auto max-[1160px]:w-full flex flex-col gap-10'>
            {guide?.image && (
              <img
                src={`https://a4medicine-charts.s3.ap-southeast-2.amazonaws.com/${guide.image}`}
                alt={`${guide.heading}`}
                className='w-full aspect-video object-cover object-center'
              />
            )}

            <div dangerouslySetInnerHTML={{ __html: guide.description }} />
          </div>
        </section>
      )}
      {!loading && !guide && (
        <p className='col-span-3 text-center text-4xl font-bold'>
          OOPS!! No Data Found
        </p>
      )}
    </CaseWrapper>
  )
}

export default GuideDetailsScreen
