import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const CancelScreen = () => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 5000)
  }, [])

  return (
    <>
      <div className='mt-[200px]'>
        <h2 className='text-center font-semibold text-6xl mt-5 text-sky-800'>
          Something Went Wrong!
        </h2>

        <p className='text-lg font-bold text-center mt-5 mb-10'>
          PAYMENT FAILED!!
        </p>
      </div>
    </>
  )
}
