import { useEffect, useState } from 'react'
import API from '../api'

export const useFetch = (endPoint) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    ;(async () => {
      const token = localStorage.getItem('token')
      setLoading(true)
      try {
        const res = await fetch(`${API}/${endPoint}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        const data = await res.json()

        if (data.status === 'fail') throw new Error(data.message)

        setData(data)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    })()
  }, [endPoint])

  return { data, loading }
}
